<template>
    <div class="page">
        <div class="head-title">
            <div class="left">
                <ul class="breadcrumb">
                    <li>
                        <router-link to="/sales/home" class="active">{{
                            $t("sales")
                            }}</router-link>
                    </li>
                    <li>-</li>
                    <li>
                        <a class="active" href="#">{{ $t("invoices") }} {{ $t("sales") }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="main">
            <div class="head">
                <h3>{{ $t("invoices") }} {{ $t("sales") }}</h3>
            </div>
            <div class="head">
                <div class="head1">
                    <input type="text" :placeholder="$t('code')" v-model="code" />
                    <input type="text" :placeholder="$t('name')" v-model="name" />
                    <input type="date" :placeholder="$t('name')" v-model="date" />
                    <button class="btn btn-primary" @click="getbycode">
                        {{ $t("search") }} <i class="bi bi-search"></i>
                    </button>
                </div>
                <div class="head2">
                    <button>
                        <router-link to="/sales/AllInvoices/CreateInvoice" class="btn-download">
                            <span class="text">
                                <i class="bi bi-clipboard-plus-fill"></i>
                                {{ $t("add") }} {{ $t("invoice") }} {{ $t("sales") }}</span>
                        </router-link>
                    </button>
                </div>
            </div>
            <div class="body">
                <table class="table">
                    <thead>
                        <tr>
                            <th>{{ $t("code") }}</th>
                            <th>{{ $t("dueon") }}</th>
                            <th>{{ $t("name") }}</th>
                            <th>{{ $t("description") }}</th>
                            <th>{{ $t("total") }}</th>
                            <th>{{ $t("payment") }}</th>
                            <th>{{ $t("status") }}</th>

                            <th v-if="isSubscribedToZatca">{{ $t("حالة فاتورة الهيئه") }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="val in data" :key="val">
                            <td>
                                <router-link :to="`/sales/InvoiceView/${val.invid}`">
                                    {{ val.invid }}</router-link>
                            </td>
                            <td>
                                {{ val.due }}
                            </td>
                            <td>
                                <router-link :to="`/sales/InvoiceView/${val.invid}`">
                                    {{ val.cname }}</router-link>
                            </td>
                            <td>
                                {{ val.description }}
                            </td>
                            <td>
                                {{ val.tat }}
                            </td>
                            <td>
                                {{ val.payment }}
                            </td>
                            <td>
                                {{ val.review }}
                            </td>

                            <td v-if="isSubscribedToZatca">
                                <span v-if="val.fai_invoice_preview_links">
                                    ارسلت واستلمت
                                </span>
                                <span v-else-if="val.sent_to_fai">
                                    ارسلت فى انتظار الرد
                                </span>
                                <span v-else>
                                    لم ترسل
                                </span>
                            </td>



                            <td>

                                <div style="display: flex">


                                    <button v-if="val.review !== 'deleted'" @click="del(val.invid)" style="padding:10px"> استرجاع</button>
                                </div>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  props: ["css"],
  data() {
    return {
      name: "",
      email: "",
      mobile: "",
      code: "",
      data: [],
      date: ""
    };
  },
  computed: {
    isSubscribedToZatca() {
      return this.$store.state.is_subscriped_to_zatca;
    }
  },
  async mounted() {
    const result = await axios.get(
      `/data/allinvoices/${store.state.cnum[0].cnum}`
    );
    if (result.status == 200) {
      console.log(store.state);
      this.data = result.data.data;
      this.data2 = result.data.data;
    }
  },
  methods: {
    InvoiceView(row) {
      // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
      this.$root.$router.push({
        path: `/AdminDashboard/InvoiceView/${row.invid}`,
        params: { id: row.id },
      });
    },
    async getbycode() {

      const result = await axios.post(`/data/Invoiceview`, {
        code: this.code,
        name: this.name,
        date: this.date
      });
      if (result.status == 200) {
        this.data = result.data.data;
      }

    },
    sendToZatca(id) {
      Swal.fire({
        title: "هل متأكد من ارسال الفاتوره الى هيئة الزكاة",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "نعم ارسل",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const request = await axios.post(`/fai/creates-sales-invoice/${id}`);
          if (request.status == 200) {
            Swal.fire("تم الارسال !", "تم ارسال الفاتوره", "success");
          }
        }
      });
    },
    del(id) {
      Swal.fire({
        title: "? هل متاكد من الحذف  ",
        text: "لن تتمكن من التراجع عن هذا! ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "نعم!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const del = await axios.delete(`/data/delinvoice/${id}`);
          if (del.status == 200) {
            location.reload();
            Swal.fire("تم الحذف !", "تم حذف الفاتوره .", "success");
          }
        }
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    // name() {
    //   this.table.rows = this.table.data.filter((x) =>
    //     x.name.includes(this.name.toLowerCase())
    //   );
    // },
    // mobile() {
    //   this.table.rows = this.table.data.filter((x) =>
    //     x.mobile.includes(this.mobile)
    //   );
    // },
    // code() {
    //   this.table.rows = this.table.data.filter((x) => x.id == this.code);
    //   if (this.code == "") {
    //     this.table.rows = this.table.data;
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.page {
    // direction: v-bind("css.EN");
    padding: 0.5%;
}

.main {
    width: 98%;
    margin: 1%;
    padding: 1%;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    border: ridge 0.5px;
}

.head {
    padding: 0.25%;
    display: flex;
    border-bottom-style: ridge;
}

.head1 {
    width: 70%;
}

.head1 input {
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
}

.head2 {
    width: 30%;
    text-align: center;
}

button {
    margin: 0.5%;
    padding: 1%;
    border: none;
    border-radius: 5px;
    background-color: rgba(98, 153, 174, 255);
    color: white;
}

.head2 button a {
    color: white;
}

.table {
    margin-top: 1%;
}

table thead tr {
    border-radius: 5px;
    background-color: #eee;
}

select {
    width: 15%;
    margin: 0.5%;
    // padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
}

@media screen and (max-width: 576px) {
    .table {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        background-color: white;
        font-size: xx-small;
    }
}
</style>
