<template>
    <div class="page">
        <div class="head-title">
            <div class="left">
                <ul class="breadcrumb">
                    <li>
                        <a href="#" class="active">{{ $t("Property") }}</a>
                    </li>
                    <li>-</li>
                    <li>
                        <router-link to="/property/allpayment" class="active">{{ $t("orders") }} {{ $t("payment") }} /
                            {{ $t("collection") }}</router-link>
                    </li>
                    <li>-</li>
                    <li>
                        <router-link to="/property/createpayment" class="active">{{ $t("receipt") }}
                            {{ $t("received") }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="main">
            <div class="head">
                <div class="head1">
                    <h3>
                        <i class="bi bi-credit-card"></i> {{ $t("receipt") }}
                        {{ $t("received") }}
                    </h3>
                </div>
                <div class="head2">
                    <div class="row">

                        <div class="col-3">
                            <button class="mtop-5 btn btn-primary" :disabled="disabled" @click="submit"
                                v-if="this.status == 'waiting' && hasPermission('receipt_voucher_confirm')">
                                <i class="bi bi-file-check-fill"></i> {{ $t("confirm") }}
                            </button>
                        </div>
                        <div class="col-3">
    <button class="mtop-5 btn btn-primary d-flex align-items-center" @click="pdf">
        <i class="bi bi-filetype-pdf"></i>  <span>{{ $t("PDF") }}</span>
    </button>
</div>
                        <div class="col-3">
                            <button class="mtop-5 btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" @click="getdata"
                                v-if="commissiontrans.length > 0">
                                فاتورة مسوده للعمولات
                            </button>
                        </div>
                        <div class="col-3">
                            <button class="mtop-5 btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal2" @click="ownerdata"
                                v-if="collamount > 0">
                                فاتورة مسوده للتحصيل
                            </button>
                        </div>
                    </div>
                    <div v-for="invoice in collectionZatcaInvoices" :key="invoice.id" class="invoice-item row">

                        <div v-if="invoice.fai_invoice_preview_links && invoice.type === 'company_to_renter'" class="mtop-5 col-6" >
                            <a :href="invoice.fai_invoice_preview_links" target="_blank" class="btn btn-primary"
                                title="Download">
                                تحميل فاتورة الشركة للمستأجر
                            </a>
                        </div>

                        <div v-if="invoice.fai_invoice_preview_links && invoice.type === 'company_to_owner'" class="mtop-5 col-6">
                            <a :href="invoice.fai_invoice_preview_links" target="_blank" class="btn btn-primary"
                                title="Download">
                                تحميل فاتورة الشركة للمالك
                            </a>
                        </div>

                        <div v-if="invoice.returned_invoice_preview_links && invoice.type === 'company_to_renter'"
                            class="col-6">
                            <a :href="invoice.returned_invoice_preview_links" target="_blank" class="mtop-5 btn btn-primary"
                                title="Download">
                                (استرجاع) تحميل فاتورة الشركة للمستأجر
                            </a>
                        </div>

                        <div v-if="invoice.returned_invoice_preview_links && invoice.type === 'company_to_owner'"
                            class="col-6">
                            <a :href="invoice.returned_invoice_preview_links" target="_blank" class="mtop-5 btn btn-primary"
                                title="Download">
                                (استرجاع) تحميل فاتورة الشركة للمالك
                            </a>
                        </div>
                    </div>
                    <!-- PDF Button -->


                    <!-- Trigger Modal for Tax Invoice if there are commission transactions -->


                    <!-- Display Invoice Links -->
                    <!-- <div>
                                <div v-for="invoice in collectionZatcaInvoices" :key="invoice.id" class="invoice-item">
                                    <span v-if="invoice.fai_invoice_preview_links && invoice.type === 'company_to_renter'">
                                        <a :href="invoice.fai_invoice_preview_links" target="_blank" class="btn btn-primary"
                                            title="Download">
                                            تحميل فاتورة الشركة للمستأجر
                                        </a>
                                    </span>

                                    <span v-if="invoice.fai_invoice_preview_links && invoice.type === 'company_to_owner'">
                                        <a :href="invoice.fai_invoice_preview_links" target="_blank" class="btn btn-primary"
                                            title="Download">
                                            تحميل فاتورة الشركة للمالك
                                        </a>
                                    </span>

                                    <span v-if="invoice.returned_invoice_preview_links && invoice.type === 'company_to_renter'">
                                        <a :href="invoice.returned_invoice_preview_links" target="_blank" class="btn btn-primary"
                                            title="Download">
                                            (استرجاع) تحميل فاتورة الشركة للمستأجر
                                        </a>
                                    </span>

                                    <span v-if="invoice.returned_invoice_preview_links && invoice.type === 'company_to_owner'">
                                        <a :href="invoice.returned_invoice_preview_links" target="_blank" class="btn btn-primary"
                                            title="Download">
                                            (استرجاع) تحميل فاتورة الشركة للمالك
                                        </a>
                                    </span>
                                </div>
                            </div> -->

                    <!-- Modal -->
                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog modal-xl">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">
                                        {{ $t("invoice") }} - {{ this.invoicenumcom }}
                                    </h5>
                                </div>
                                <div class="modal-body">
                                    <!--  -->
                                    <div id="main2" style="padding: 2%">
                                        <div style="padding: 2%; direction: rtl; font-size: small">
                                            <!-- <img src="./logo.png" style="height: 200px; width: 100%" /> -->
                                            <img :src="this.imgurl" crossorigin="anonymous"
                                                style="height: 100px; width: 25%; float: right" />
                                            <qrcode-vue style="float: left" render-as="svg" :value="qrCodeString"
                                                :size="150" level="H" />

                                            <div class="part1" style="clear: both; text-align: right">
                                                <div class="part11">
                                                    <h3>فاتورة ضريبية</h3>
                                                    {{ $t("number") }} : REC-
                                                    {{ this.invoicenumcom }}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    {{ $t("date") }} : {{ paydate }} / {{ time }}
                                                    <br />
                                                </div>
                                                <hr />
                                                <div class="part12">
                                                    <!-- <h4>معلومات البائع</h4> -->
                                                    <div style="padding: 2%">
                                                        <h5>{{ this.$store.state.cnum[0].cname }}</h5>
                                                        {{ this.$store.state.cnum[0].caddress }}
                                                        <br />
                                                        رقم تسجيل ضريبة القيمة المضافة :
                                                        {{ this.$store.state.cnum[0].taxnum }}
                                                    </div>
                                                </div>
                                                <div class="part12">
                                                    <div style="padding: 2%">
                                                        اسم العميل :
                                                        <h5>{{ rentername }}</h5>
                                                        رقم العقد :
                                                        {{ igarnumber }}
                                                        <br />
                                                        عنوان :
                                                        {{ cdata.address }}
                                                        <br />
                                                        رقم تسجيل ضريبة القيمة المضافة :
                                                        {{ cdata.taxnumber }}
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div class="part2">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>{{ $t("type") }}</th>
                                                            <th>{{ $t("description") }}</th>
                                                            <th>{{ $t("amount") }}</th>
                                                            <th>{{ $t("tax") }}</th>
                                                            <th>{{ $t("total") }}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(y, id) in commissiontrans" :key="y">
                                                            <td v-if="y.payamount > 0">
                                                                {{ id + 1 }}
                                                            </td>
                                                            <td v-if="y.payamount > 0">{{ $t(y.type) }} {{ $t('date') }} :
                                                                {{ y.duedate }}</td>
                                                            <td v-if="y.payamount > 0">
                                                                {{ y.description }}
                                                            </td>
                                                            <td v-if="y.payamount > 0">
                                                                {{ Number(y.payamount).toFixed(2) }}
                                                            </td>
                                                            <td v-if="y.payamount > 0">
                                                                {{ Number(y.paytax).toFixed(2) }}
                                                            </td>
                                                            <td v-if="y.payamount > 0">
                                                                {{
                                                                Number(
                                                                Number(y.paytax) + Number(y.payamount)
                                                                ).toFixed(2)
                                                                }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <!-- <tfoot>
                                                                            <tr>
                                                                              <td></td>
                                                                              <td colspan="2">{{ $t("total") }}</td>
                                                                              <td>{{ Number(billpayamount).toFixed(2) }}</td>
                                                                              <td>{{ Number(billpaytax).toFixed(2) }}</td>
                                                                              <td>
                                                                                {{
                                                                                  Number(
                                                                                    Number(billpayamount) + Number(billpaytax)
                                                                                  ).toFixed(2)
                                                                                }}
                                                                              </td>
                                                                            </tr>
                                                                          </tfoot> -->
                                                </table>
                                            </div>
                                            <hr />
                                            <div style="width: 20%;margin-right: 70%;">
                                                <table class="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>الاجمالى قبل الضريبة</td>
                                                            <td>{{ Number(billpayamount).toFixed(2) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ضريبة القيمة المضافة 15%</td>
                                                            <td>{{ Number(billpaytax).toFixed(2) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>الاجمالى</td>
                                                            <td> {{
                                                                Number(
                                                                Number(billpayamount) + Number(billpaytax)
                                                                ).toFixed(2)
                                                                }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <hr />
                                            {{ $t("description") }} :

                                            {{ description }}
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                    <!--  -->
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                        {{ $t("close") }}
                                    </button>
                                    <button type="button" class="btn btn-primary" @click="pdf2">
                                        {{ $t("printe") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Modal -->
                    <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog modal-xl">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">
                                        {{ $t("invoice") }} - {{ this.invoicenum }}
                                    </h5>
                                </div>
                                <div class="modal-body">
                                    <!--  -->
                                    <div id="main2" style="padding: 2%">
                                        <div style="padding: 2%; direction: rtl; font-size: small">
                                            <!-- <img src="./logo.png" style="height: 200px; width: 100%" /> -->
                                            <img :src="this.imgurl" crossorigin="anonymous"
                                                style="height: 100px; width: 25%; float: right" />
                                            <qrcode-vue style="float: left" render-as="svg" :value="qrCodeString2"
                                                :size="150" level="H" />

                                            <div class="part1" style="clear: both; text-align: right">
                                                <div class="part11">
                                                    <h3>فاتورة ضريبية</h3>
                                                    {{ $t("number") }} : REC-
                                                    {{ this.invoicenum }}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    {{ $t("date") }} : {{ paydate }} / {{ time }}
                                                    <br />
                                                </div>
                                                <hr />
                                                <div class="part12">
                                                    <!-- <h4>معلومات البائع</h4> -->
                                                    <div style="padding: 2%">
                                                        <h5>{{ this.$store.state.cnum[0].cname }}</h5>
                                                        {{ this.$store.state.cnum[0].caddress }}
                                                        <br />
                                                        رقم تسجيل ضريبة القيمة المضافة :
                                                        {{ this.$store.state.cnum[0].taxnum }}
                                                    </div>
                                                </div>
                                                <div class="part12">
                                                    <div style="padding: 2%">
                                                        اسم العميل :
                                                        <h5>{{ ownername }}</h5>
                                                        <br />
                                                        عنوان :
                                                        {{ owneraddress }}
                                                        <br />
                                                        رقم تسجيل ضريبة القيمة المضافة :
                                                        {{ ownertaxnumber }}
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div class="part2">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>{{ $t("type") }}</th>
                                                            <th>{{ $t("description") }}</th>
                                                            <th>{{ $t("amount") }}</th>
                                                            <th>{{ $t("tax") }}</th>
                                                            <th>{{ $t("total") }}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>عمولة تحصيل ايجار عقار {{ property }}</td>
                                                            <td></td>
                                                            <td>{{ collamount }}</td>
                                                            <td>{{ (collamount * 15) / 100 }}</td>
                                                            <td>
                                                                {{
                                                                Number(
                                                                collamount + (collamount * 15) / 100
                                                                ).toFixed(2)
                                                                }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <hr />
                                            <div style="width: 20%;margin-right: 70%;">
                                                <table class="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>الاجمالى قبل الضريبة</td>
                                                            <td>{{ collamount }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ضريبة القيمة المضافة 15%</td>
                                                            <td>{{ (collamount * 15) / 100 }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>الاجمالى</td>
                                                            <td> {{
                                                                Number(
                                                                collamount + (collamount * 15) / 100
                                                                ).toFixed(2)
                                                                }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <hr />
                                            {{ $t("description") }} :
                                            {{ description }}
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                    <!--  -->
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                        {{ $t("close") }}
                                    </button>
                                    <button type="button" class="btn btn-primary" @click="pdf2">
                                        {{ $t("printe") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="body">
                <div class="body1">
                    <div class="group">
                        <div class="mb-1 row">
                            <label class="col-sm-4 col-form-label">{{ $t("receipt") }}
                            </label>
                            <div class="col-sm-7">
                                {{ this.$route.params.id }}
                            </div>
                        </div>
                        <div class="mb-1 row">
                            <label class="col-sm-4 col-form-label">{{ $t("date") }} {{ $t("payment") }}
                            </label>
                            <div class="col-sm-7">
                                {{ paydate }}
                            </div>
                        </div>
                        <div class="mb-1 row">
                            <label class="col-sm-4 col-form-label">{{ $t("realty") }} </label>
                            <div class="col-sm-7">
                                {{ property }}
                            </div>
                        </div>
                        <div class="mb-1 row">
                            <label class="col-sm-4 col-form-label">{{ $t("unit") }} </label>
                            <div class="col-sm-7">
                                {{ unit }}
                            </div>
                        </div>
                        <div class="mb-1 row">
                            <label class="col-sm-4 col-form-label">{{ $t("renter") }} </label>
                            <div class="col-sm-7">
                                {{ rentername }}
                            </div>
                        </div>

                        <!-- <div class="mb-1 row">
                                                                <label class="col-sm-4 col-form-label">{{ $t("number") }} {{ $t("contract") }}</label>
                                                                <div class="col-sm-7">
                                                                  {{ contractnum }}
                                                                </div>
                                                              </div> -->
                        <div class="mb-1 row">
                            <label class="col-sm-4 col-form-label">رقم عقد ايجار </label>
                            <div class="col-sm-7">
                                {{ igarnumber }}
                            </div>
                        </div>
                    </div>
                    <div class="group">
                        <div class="mb-1 row">
                            <label class="col-sm-4 col-form-label">{{ $t("date") }} {{ $t("creation") }}
                            </label>
                            <div class="col-sm-7">
                                {{ creationdate }}
                            </div>
                        </div>
                        <div class="mb-1 row">
                            <label class="col-sm-4 col-form-label">{{ $t("Method of Payment") }}
                            </label>
                            <div class="col-sm-7">
                                {{ method }}
                            </div>
                        </div>
                        <div class="mb-1 row">
                            <label class="col-sm-4 col-form-label">{{ $t("Account") }}
                            </label>
                            <div class="col-sm-7">
                                {{ accountpayment }}
                            </div>
                        </div>
                        <!-- {{ element }} -->
                        <!-- <div class="mb-1 row">
                                                              <label class="col-sm-4 col-form-label">{{ $t("total") }}
                                                              </label>
                                                              <div class="col-sm-7">
                                                                {{ Number(total).toFixed(2) }}
                                                              </div>
                                                            </div> -->
                        <!-- <div class="mb-1 row">
                                                              <label class="col-sm-4 col-form-label"
                                                                >اجمالى المتبقى من العقد
                                                              </label>
                                                              <div class="col-sm-7">
                                                                {{ Number(dueamount).toFixed(2) }}
                                                              </div>
                                                            </div>
                                                            <div class="mb-1 row">
                                                              <label class="col-sm-4 col-form-label"
                                                                >اجمالى المسدد من العقد</label
                                                              >
                                                              <div class="col-sm-7">
                                                                {{ Number(allpayments).toFixed(2) }}
                                                              </div>
                                                            </div> -->
                        <div class="mb-1 row" v-if="firstinstallmentchange > 0">
                            <label class="col-sm-4 col-form-label">
                                المتبقى من القسط المدفوع
                            </label>
                            <div class="col-sm-7">
                                {{ Number(firstinstallmentchange).toFixed(2) }}
                            </div>
                        </div>
                        <div class="mb-1 row" v-if="commission.length > 0">
                            <label class="col-sm-4 col-form-label">
                                المتبقى من العمولات
                            </label>
                            <div class="col-sm-7">
                                <table>
                                    <tbody>
                                        <tr v-for="y in commission" :key="y">
                                            <td v-if="Number(y.amount - y.paied).toFixed(2) != 0">
                                                {{ y.name }} {{ $t('date') }} الاستحقاق {{ y.duedate }}
                                            </td>
                                            <td v-if="Number(y.amount - y.paied).toFixed(2) != 0">
                                                :
                                            </td>
                                            <td v-if="Number(y.amount - y.paied).toFixed(2) != 0">
                                                {{ Number(y.amount - y.paied).toFixed(2) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="mb-1 row" v-if="commission.length > 0">
                            <label class="col-sm-4 col-form-label">
                                اجمالى المتبقى من العمولات
                            </label>
                            <div class="col-sm-7">
                                {{ totalduecommission }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="group">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{{ $t("type") }}</th>
                                <th>{{ $t("description") }}</th>
                                <th>{{ $t("amount") }}</th>
                                <th>{{ $t("tax") }}</th>
                                <th>{{ $t("total") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(y, id) in trans" :key="y">
                                <td v-if="y.payamount > 0">
                                    {{ id + 1 }}
                                </td>
                                <td v-if="y.payamount > 0">{{ $t(y.type) }}</td>
                                <td v-if="y.payamount > 0">
                                    {{ y.description }}
                                </td>
                                <td v-if="y.payamount > 0">
                                    {{ Number(y.payamount).toFixed(2) }}
                                </td>
                                <td v-if="y.payamount > 0">
                                    {{ Number(y.paytax).toFixed(2) }}
                                </td>
                                <td v-if="y.payamount > 0">
                                    {{
                                    Number(Number(y.paytax) + Number(y.payamount)).toFixed(2)
                                    }}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td colspan="2">{{ $t("total") }}</td>
                                <td>{{ Number(payamount).toFixed(2) }}</td>
                                <td>{{ Number(paytax).toFixed(2) }}</td>
                                <td>
                                    {{ Number(Number(payamount) + Number(paytax)).toFixed(2) }}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div v-if="this.file.length > 0">
                <div class="mb-2 row">
                    <label class="col-sm-2 col-form-label-sm">
                        <i class="bi bi-pin-angle-fill"></i>
                        {{ $t("attachments") }}
                    </label>
                    <div class="breadcrumb">
                        <ul v-for="value of fileurl" :key="value">
                            <li>
                                <a :href="this.url + value.url" target="_blank" :download="value.name"><i
                                        class="bi bi-download"></i>
                                    &nbsp; {{ value.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="activity">
                <ul v-for="x in activity" :key="x">
                    <li style="width: 150px">
                        {{ x.action }} <br />
                        <span style="font-size: x-small">{{ x.date }} - {{ x.time }}</span>
                        <br v-if="x.comment" />{{ x.comment }} <br />{{ x.username }}
                    </li>
                </ul>
            </div>
            <div id="main" style="display: none">
                <div style="padding: 2%; direction: rtl; font-size: small">
                    <div class="body1">
                        <div class="group">
                            <h3 style="padding: 2%">
                                {{ $t("receipt") }}
                                {{ $t("received") }}
                            </h3>
                        </div>
                        <div class="group">
                            <img :src="this.imgurl" crossorigin="anonymous"
                                style="height: 100px; width: 50%; float: left" />
                        </div>
                    </div>
                    <div class="body1">
                        <div class="group">
                            <div class="mb-1 row">
                                <label class="col-sm-4 col-form-label">{{ $t("receipt") }}
                                </label>
                                <div class="col-sm-7">
                                    {{ this.$route.params.id }}
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label class="col-sm-4 col-form-label">{{ $t("date") }} {{ $t("payment") }}
                                </label>
                                <div class="col-sm-7">
                                    {{ paydate }}
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label class="col-sm-4 col-form-label">{{ $t("realty") }}
                                </label>
                                <div class="col-sm-7">
                                    {{ property }}
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label class="col-sm-4 col-form-label">{{ $t("unit") }} </label>
                                <div class="col-sm-7">
                                    {{ unit }}
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label class="col-sm-4 col-form-label">{{ $t("renter") }}
                                </label>
                                <div class="col-sm-7">
                                    {{ rentername }}
                                </div>
                            </div>

                            <!-- <div class="mb-1 row">
                                                                <label class="col-sm-4 col-form-label">{{ $t("number") }} {{ $t("contract") }}</label>
                                                                <div class="col-sm-7">
                                                                  {{ contractnum }}
                                                                </div>
                                                              </div> -->
                            <div class="mb-1 row">
                                <label class="col-sm-4 col-form-label">رقم عقد ايجار </label>
                                <div class="col-sm-7">
                                    {{ igarnumber }}
                                </div>
                            </div>
                        </div>
                        <div class="group">
                            <div class="mb-1 row">
                                <label class="col-sm-4 col-form-label">{{ $t("date") }} {{ $t("creation") }}
                                </label>
                                <div class="col-sm-7">
                                    {{ creationdate }}
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label class="col-sm-4 col-form-label">{{ $t("Method of Payment") }}
                                </label>
                                <div class="col-sm-7">
                                    {{ method }}
                                </div>
                            </div>
                            <div class="mb-1 row">
                                <label class="col-sm-4 col-form-label">{{ $t("Account") }}
                                </label>
                                <div class="col-sm-7">
                                    {{ accountpayment }}
                                </div>
                            </div>
                            <!-- <div class="mb-1 row">
                                                                <label class="col-sm-4 col-form-label">{{ $t("total") }}
                                                                </label>
                                                                <div class="col-sm-7">
                                                                  {{ Number(total).toFixed(2) }}
                                                                </div>
                                                              </div> -->
                            <div class="mb-1 row" v-if="firstinstallmentchange > 0">
                                <label class="col-sm-4 col-form-label">
                                    المتبقى من القسط المدفوع
                                </label>
                                <div class="col-sm-7">
                                    {{ Number(firstinstallmentchange).toFixed(2) }}
                                </div>
                            </div>
                            <div class="mb-1 row" v-if="commission.length > 0">
                                <label class="col-sm-4 col-form-label">
                                    المتبقى من العمولات
                                </label>
                                <div class="col-sm-7">
                                    <table>
                                        <tbody>
                                            <tr v-for="y in commission" :key="y">
                                                <td v-if="Number(y.amount - y.paied).toFixed(2) != 0">
                                                    {{ y.name }}
                                                </td>
                                                <td v-if="Number(y.amount - y.paied).toFixed(2) != 0">
                                                    :
                                                </td>
                                                <td v-if="Number(y.amount - y.paied).toFixed(2) != 0">
                                                    {{ Number(y.amount - y.paied).toFixed(2) }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="mb-1 row" v-if="commission.length > 0">
                                <label class="col-sm-4 col-form-label">
                                    المتبقى من العمولات
                                </label>
                                <div class="col-sm-7">
                                    {{ totalduecommission }}
                                </div>
                            </div>
                            <div class="mb-1 row">
                                {{ $t("description") }} :
                                يوجد معدل للضريبة 15 %
                                {{ description }}
                            </div>
                        </div>
                    </div>
                    <div class="group">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{{ $t("type") }}</th>
                                    <th>{{ $t("description") }}</th>
                                    <th>{{ $t("amount") }}</th>
                                    <th>{{ $t("tax") }}</th>
                                    <th>{{ $t("total") }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(y, id) in trans" :key="y">
                                    <td v-if="y.payamount > 0">
                                        {{ id + 1 }}
                                    </td>
                                    <td v-if="y.payamount > 0">{{ $t(y.type) }}</td>
                                    <td v-if="y.payamount > 0">
                                        {{ y.description }}
                                    </td>
                                    <td v-if="y.payamount > 0">
                                        {{ Number(y.payamount).toFixed(2) }}
                                    </td>
                                    <td v-if="y.payamount > 0">
                                        {{ Number(y.paytax).toFixed(2) }}
                                    </td>
                                    <td v-if="y.payamount > 0">
                                        {{
                                        Number(Number(y.paytax) + Number(y.payamount)).toFixed(2)
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td colspan="2">{{ $t("total") }}</td>
                                    <td>{{ Number(payamount).toFixed(2) }}</td>
                                    <td>{{ Number(paytax).toFixed(2) }}</td>
                                    <td>
                                        {{ Number(Number(payamount) + Number(paytax)).toFixed(2) }}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import { Buffer } from "buffer";
import axios from "axios";
import store from "@/store";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import html2pdf from "html2pdf.js";
import moment from "moment";
export default {
  props: ["css"],
  components: { QrcodeVue },
  async mounted() {
    this.imgurl =
      store.state.imgurl + `/clogo/${this.$store.state.cnum[0].cnum}.png`;
    const result = await axios.get(`/data/getpayment/${this.$route.params.id}`);
    if (result.status == 200) {
      const trans = result.data.data;
      this.collectionZatcaInvoices = result.data.collectionZatcaInvoices;
      console.log(this.collectionZatcaInvoices);
      if (trans[0].fileurl != null) {
        this.file = JSON.parse(trans[0].fileurl)
      }
      for (let i = 0; i < trans.length; i++) {
        const element = trans[i];
        if (element.type == "installment") {
          const paiedinstallments = JSON.parse(element.paiedinstallments);
          if (paiedinstallments) {
            for (let x = 0; x < paiedinstallments.length; x++) {
              const element2 = paiedinstallments[x];
              const amount = element2.payamount / 1.15;
              const date = moment(element2.duedate)
                .add(element.periodtime, "M")
                .format("YYYY-MM-DD");
              if (element.paytax > 0) {
                this.trans.push({
                  payamount: amount,
                  type: element.type,
                  paytax: (amount * 15) / 100,
                  description: ` عن الفترة من ${element2.duedate} الى ${date}`,
                });
              } else {
                this.trans.push({
                  payamount: element2.payamount,
                  type: element.type,
                  paytax: 0,
                  description: ` عن الفترة من ${element2.duedate} الى ${date}`,
                });
              }
            }
          }
        } else {
          // const date = moment(element.duedate)
          //   .add(1, "y")
          //   .format("YYYY-MM-DD");
          this.trans.push({
            payamount: element.payamount,
            type: element.type,
            paytax: element.paytax,
            description: element.description + `تاريخ الاستحقاق ${element.duedate}`,
          });
        }

      }

      const data = result.data.data.filter((x) => x.type == "installment")[0];

      this.commissiontrans = result.data.data.filter(
        (x) => x.type != "installment" && x.code != 4
      );

      this.todate = moment(data.duedate)
        .add(data.periodtime * data.installmentnum, "M")
        .format("YYYY-MM-DD");
      this.property = data.proname;
      this.rentername = data.rentername;
      this.renterid = data.renterid;
      this.paydate = data.paydate;
      this.time = data.time;
      this.tinvnum = data.tinvnum;
      this.creationdate = data.date;
      this.method = data.paytype;
      this.accountpayment = JSON.parse(data.payaccount).namear;
      this.contractnum = data.conid;
      this.unit = data.unit;
      this.firstinstallmentchange = data.change;
      this.collamount = Number(data.collamount);
      this.invoicenum = data.tinvnum;

      this.proid = data.proid;
      this.total =
        Number(data.amount) + Number(data.comtotal) + Number(data.comtax);
      this.igarnumber = data.igarnumber;
      this.payamount = this.trans.reduce(
        (acc, array) => acc + Number(array.payamount),
        0
      );
      this.paytax = this.trans.reduce(
        (acc, array) => acc + Number(array.paytax),
        0
      );
      this.billpayamount = this.commissiontrans.reduce(
        (acc, array) => acc + Number(array.payamount),
        0
      );
      this.billpaytax = this.commissiontrans.reduce(
        (acc, array) => acc + Number(array.paytax),
        0
      );
      this.status = data.status;
      const result3 = await axios.get(
        `/reports/getallcommissionstodate2/${data.conid}/${trans[0].paydate}`
      );
      if (result3.status == 200) {
        this.commission = result3.data.data;
      }
    }
    const result4 = await axios.get(
      `/reports/getallinstallment/${this.contractnum}`
    );
    if (result4.status == 200) {
      const data = result4.data.data;
      this.dueamount = data.reduce(
        (acc, array) => acc + Number(array.change),
        0
      );
    }

    // const result3 = await axios.get(
    //   `/reports/getallpayments/${this.contractnum}`
    // );
    // if (result3.status == 200) {
    //   this.allpayments = result3.data.data[0].amount;
    // }
    // this.url =
    //   store.state.imgurl + `/clogo/${this.$store.state.cnum[0].cnum}.png`;
    for (let i = 0; i < this.file.length; i++) {
      this.fileurl.push({
        name: this.file[i].name,
        url: store.state.cnum[0].cnum + "_" + this.$route.params.id + "_" + i + "." + this.file[i].type
      })

    }

    const totalamountcomm = this.commission.reduce(
      (acc, array) => acc + Number(array.amount),
      0
    );
    const totalpaid = this.commission.reduce(
      (acc, array) => acc + Number(array.paied),
      0
    );
    this.totalduecommission = Number(Number(totalamountcomm) - Number(totalpaid)).toFixed(2)
    this.url = store.state.imgurl + `/paymentattach/`;
    this.activity = result.data.activity;
    this.invoicenumcom = this.commissiontrans[0].tinvnum;
  },
  data() {
    return {
      v$: useValidate(),
      rentername: "",
      property: "",
      comm: "",
      paydate: "",
      creationdate: "",
      trans: [],
      contract: "",
      contstatus: "",
      payamount: "",
      paytax: "",
      contduration: "",
      contamount: "",
      Method: "",
      contractnum: "",
      accountpayment: "",
      account: "",
      paymentdate: "",
      conductor: "",
      commission: [],
      paymentamount: "",
      propertyaccount: "",
      propertytaxaccount: "",
      renteraccount: "",
      totalcommission: 0,
      totalcommissiontax: 0,
      unit: "",
      description: "",
      commissionaccount: "",
      ownerid: "",
      totalrentpayment: 0,
      totalrentpaymenttax: 0,
      installment: [],
      igarnumber: "",
      url: "",
      imgurl: "",
      status: "",
      activity: [],
      renterid: "",
      cdata: "",
      commissiontrans: [],
      tinvnum: "",
      qrvalue: "",
      sellerName: "Example Seller",
      vatNumber: "123456789012345",
      timestamp: "2023-06-12T14:30:00",
      invoiceTotal: "1000.00",
      vatTotal: "150.00",
      qrCodeString: "",
      dueamount: "",
      allpayments: "",
      todate: "",
      firstinstallmentchange: "",
      collamount: "",
      invoicenum: "",
      invoicenumcom: "",
      proid: "",
      ownername: "",
      owneraddress: "",
      ownertaxnumber: "",
      file: "",
      fileurl: [],
      totalduecommission: "",
      collectionZatcaInvoices: ""

    };
  },
  validations() {
    return {
      property: { required },
      contract: { required },
      paymentamount: { required },
      Method: { required },
      account: { required },
      paymentdate: { required },
    };
  },
  methods: {
    async submit() {
      Swal.fire({
        title: this.$t("confirm") + "-" + this.$t("payment"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/confirmwaitpayment", {
            username: store.state.randp.username,
            id: this.$route.params.id,
          });
          if (action.status == 200) {
            Swal.fire(this.$t("done") + " " + this.$t("payment"));
            this.redirectTo({ val: "allpayments" });
          }
        }
      });
    },
    async ownerdata() {
      const data = await axios.get(`/reports/ownerdata/${this.proid}`);
      if (data.status == 200) {
        this.ownername = data.data.data[0].name;
        this.owneraddress = data.data.data[0].address;
        this.ownertaxnumber = data.data.data[0].taxnumber;
        const encoded = Buffer.concat([
          this.tlvEncode(0x01, this.$store.state.cnum[0].cname),
          this.tlvEncode(0x02, this.$store.state.cnum[0].taxnum),
          this.tlvEncode(0x03, this.paydate),
          this.tlvEncode(0x04, this.collamount),
          this.tlvEncode(0x05, (this.collamount * 15) / 100),
        ]);
        this.qrCodeString2 = Buffer.from(encoded).toString("base64");
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.5,
        filename: `coll-${this.$route.params.id}.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    pdf2() {
      html2pdf(document.getElementById("main2").innerHTML, {
        margin: 0.5,
        filename: `REC-${this.$route.params.id}.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    async getdata() {
      const action = await axios.get(`/data/delcustomer/${this.renterid}`);
      if (action.status == 200) {
        this.cdata = action.data.data[0];
      }
      const encoded = Buffer.concat([
        this.tlvEncode(0x01, this.$store.state.cnum[0].cname),
        this.tlvEncode(0x02, this.$store.state.cnum[0].taxnum),
        this.tlvEncode(0x03, this.paydate),
        this.tlvEncode(0x04, this.billpayamount),
        this.tlvEncode(0x05, this.billpaytax),
      ]);
      this.qrCodeString = Buffer.from(encoded).toString("base64");
    },
    tlvEncode(tag, value) {
      const valueBytes = new TextEncoder().encode(value);
      const length = valueBytes.length;
      return Buffer.from([tag, length, ...valueBytes]);
    },

    print() {
      var divElements = document.getElementById("main").innerHTML;
      var WinPrint = window.open();
      WinPrint.document.write(
        "<html><head><title>ESLAM</title></head><body>" +
        divElements +
        "</body>"
      );
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    print2() {
      this.$htmlToPaper("main");
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.page {
    direction: v-bind("css.EN");
    padding: 0.5%;
}

.main {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1%;
}

.body {
    padding: 1%;
    // min-height: 500px;
    //   border: 1px solid black;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.body1 {
    padding: 1%;
    width: 100%;
    min-height: 100px;
    border: 1px solid black;
    display: flex;
    border-radius: 5px;
}

.body2 {
    width: 100%;
    min-height: 100px;
    display: flex;
    border: 1px solid black;
    border-radius: 5px;
}

.body3 {
    width: 100%;
    min-height: 100px;
}

.body4 {
    padding: 1%;
    width: 100%;
    min-height: 100px;
    background-color: #eee;
}

.part {
    font-size: large;
    width: 99%;
    padding: 2%;
    margin: 0.5%;
    background-color: white;
    border-radius: 5px;
    color: black;
    border: 1px solid black;
    font-size: small;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ul {
    text-decoration: none;
    list-style-type: none;
    color: black;
}

.bi {
    margin: 1%;
}

.head {
    padding: 0.25%;
    display: flex;
    border-bottom-style: ridge;
}

.head1 {
    width: 40%;
}

.head1 input {
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
}

.head2 {
    width: 60%;
    text-align: center;
}

// .head2 button {
//     margin: 0.5%;
//     padding: 1%;
//     border: none;
//     border-radius: 5px;
//     background-color: rgba(98, 153, 174, 255);
//     color: white;
//     font-size: small;
//     width: 15%;
// }
.btn-primary {
    background-color: rgba(98, 153, 174, 255);
    border-color: #32515c;
    transition: all 0.3s ease; /* Smooth transition for the hover effect */
}

.btn-primary:hover {
    background-color: #4b7f8e; /* A slightly darker shade for hover */
    border-color: #3c5e67; /* A darker border color for hover */
}
.head2 button a {
    color: white;
}
.mtop-5{
    margin-top: 5px;
}
.group {
    width: 100%;
    padding: 1%;
    margin: 1%;
}

.group2 {
    width: 100%;
    background-color: #eee;
    display: flex;
}

.group3 {
    width: 100%;
    background-color: #eee;
    display: flex;
}

.group input {
    width: 80%;
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
    text-align: center;
}

.group2 input {
    width: 100%;
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
    text-align: center;
}

.group3 input {
    width: 50%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
    text-align: center;
}

#preview {
    width: 50%;
}

#preview img {
    width: 100%;
    max-height: 200px;
}

.selection button {
    width: 10%;
    border: none;
    background-color: white;
}

.selection .active {
    width: 10%;
    border: none;
    background-color: #eee;
}

table tbody td input {
    border-radius: 3px;
    background-color: #eee;
    text-align: center;
    height: 25px;
    font-size: small;
}

table tbody td select {
    border-radius: 3px;
    background-color: #eee;
    text-align: center;
    height: 25px;
    font-size: small;
}

.body4 button {
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: rgba(98, 153, 174, 255);
    color: white;
    font-size: small;
}

.option {
    text-align: center;
}

.input-group-text {
    width: 20%;
    border: none;
}

// .row {
//     background-color: rgb(236, 232, 232);
// }

.activity {
    // display: flex;
    margin-top: 2%;
    width: 75%;
    font-size: small;
}
</style>
