import axios from "axios";
// import store from "./store/index";

function getCookie() {
  let name = "token" + "=";
  let ca = document.cookie.split(";");
  if (ca[0] != "") {
    console.log(ca);


    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }

    return "";
  } else {
    localStorage.clear();
    // window.location.href = `/SignIn`;
  }
}
// if (getCookie() == "") {
//   window.location.href = `${store.state.surl}/SignIn`;
// }
const token = getCookie();
axios.defaults.headers.common["Authorization"] = "bearer " + token;
// axios.defaults.baseURL = "http://demo-backend.hesabtech.com";
// axios.defaults.baseURL = "http://idmi-backend.hesabtech.com";
// axios.defaults.baseURL = "http://92.205.190.86:3000";
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_BASE_URL

