<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("management") }} {{ $t("projects") }}</a>
          </li>
          <li>-</li>
          <li>
            <a href="#">{{ $t("receipts") }} {{ $t("suppliers") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ $t("add") }} {{ $t("receipts") }} {{ $t("suppliers") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("add") }} {{ $t("receipts") }} {{ $t("suppliers") }}</h3>
        </div>
        <div class="head2">
          <button class="buttonconfirm" type="submit" :disabled="disabled" @click="submit">
            {{ $t("save") }}
          </button>
        </div>
      </div>
      <div class="body">
        <div class="part1">
          <div class="part11">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("number") }}
              </label>
              <div class="col-sm-7">PYT-{{ id }}</div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }}
                <span class="error-feedback" v-if="v$.date.$error">{{
                  "*"
                }}</span></label>
              <div class="col-sm-7">
                <input type="date" class="form-control form-control-sm" id="inputEmail3" v-model="date" />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("description") }}
                <span class="error-feedback" v-if="v$.description.$error">{{
                  "*"
                }}</span></label>
              <div class="col-sm-7">
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                  v-model="description"></textarea>
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("supplier") }}
                <span class="error-feedback">{{
                  "*"
                }}</span>
              </label>
              <div class="col-sm-7">
                <v-select :options="customers" label="name" v-model="cdata">
                  <template #option="option">
                    <span>{{ option.name }}</span>
                  </template>
                </v-select>
              </div>
            </div>

            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("Account") }}
                <span class="error-feedback">{{
                  "*"
                }}</span></label>
              <div class="col-sm-7">
                <select class="form-control form-control-sm" style="width: 100%" @change="
                  this.accountno = this.account.actnum;
                this.accountnamear = this.account.namear;
                this.accountnameen = this.account.nameen;
                " v-model="account">
                  <option v-for="value in accountpayment" :key="value" :value="value">
                    {{ value.namear }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("project") }}
              </label>
              <div class="col-sm-7">
                <v-select :options="allprojects" label="name" v-model="project">
                  <template #option="option">
                    <span>{{ option.name }}</span>
                  </template>
                </v-select>
              </div>
            </div>
            <!--  -->
            <!-- <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("type") }}
                <span class="error-feedback" v-if="v$.description.$error">{{
                  "*"
                }}</span></label>
              <div class="col-sm-7">
                <select class="form-control form-control-sm" v-model="type">
                  <option value="received">{{ $t("received") }}</option>
                  <option value="paid">{{ $t("paid") }}</option>
                </select>
              </div>
            </div> -->
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("amount") }}
                <span class="error-feedback" v-if="v$.amount.$error">{{
                  "*"
                }}</span></label>
              <div class="col-sm-7">
                <input type="number" class="form-control form-control-sm" id="inputEmail3" @change="calc2"
                  v-model="amount" />
              </div>
            </div>
          </div>
          <div class="part11">
            <h4>{{ $t("data") }} {{ $t("customer") }}</h4>

            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("name") }}
              </label>
              <div class="col-sm-7">
                {{ customerdata.name }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("mobile") }}
              </label>
              <div class="col-sm-7">
                {{ customerdata.mobile }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("email") }}
              </label>
              <div class="col-sm-7">
                {{ customerdata.email }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("tax") }}
              </label>
              <div class="col-sm-7">
                {{ customerdata.taxnumber }}
              </div>
            </div>
          </div>
        </div>
        <!-- Button trigger modal -->
        <!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"
          style="margin: 1%;padding: 1%;">
          اضافة مقاول
        </button> -->

        <!-- Modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h6>اضافة مقاول</h6>
              </div>
              <div class="modal-body">
                <div class="row mb-2">
                  <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("contractor") }} <span
                      class="error-feedback">{{
                        "*"
                      }}</span>
                  </label>
                  <div class="col-sm-7">
                    <v-select :options="allcontractors" label="name" v-model="contractor">
                      <template #option="option">
                        <span>{{ option.name }}</span>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary">Save changes</button>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="row mb-2" v-if="unpaidinvoice.length > 0">
          <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("invoices") }} {{ $t("customer") }}
          </label>
          <div class="col-sm-7">
            <table class="table">
              <thead>
                <tr>
                  <th>{{ $t("number") }} {{ $t("invoice") }}</th>
                  <th>{{ $t("date") }}</th>
                  <th>{{ $t("amount") }}</th>
                  <th>{{ $t("received") }}</th>
                  <th>{{ $t("amount") }}</th>
                  <th>{{ $t("remaining") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="val in unpaidinvoice" :key="val">
                  <td>BILL-{{ val.bid }}</td>
                  <td>{{ val.due }}</td>
                  <td>{{ val.tat }}</td>
                  <td>{{ val.payment }}</td>
                  <td>
                    <input type="number" class="form-control form-control-sm" id="inputEmail3" @change="calc"
                      v-model="val.amount" />
                  </td>
                  <td>{{ val.change }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td>{{ totalamount }}</td>
                  <td>{{ totalreceived }}</td>
                  <td v-if="totalpaid > 0">{{ totalpaid }}</td>
                  <td>{{ totalremaining }}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <h3 v-if="unpaid == true">لا يوجد فواتير للعميل لم يتم سدادها</h3>
        <!--  -->
        <div class="row mb-2" v-if="this.recremaining > 0">
          <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("amount") }}
            <span class="error-feedback" v-if="v$.amount.$error">{{
              "*"
            }}</span></label>
          <div class="col-sm-7">
            {{ recremaining }}
          </div>
        </div>
        <!--  -->
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  props: ["css"],
  async mounted() {
    const result3 = await axios.get("/data/allsupplier");
    if (result3.status == 200) {
      this.customers = result3.data.data;
    }
    const result2 = await axios.get("/data/accountpayment");
    if (result2.status == 200) {
      this.accountpayment = result2.data.data;
      this.accountpayment.push({ actnum: 0, namear: 'المالك', nameen: 0 })
    }
    const result4 = await axios.get("/data/allprojects");
    const result5 = await axios.get("/data/alltasks");
    const result1 = await axios.get("/data/allbranches");
    const result6 = await axios.get("/data/receiptID");
    if (result4.status == 200) {
      this.allprojects = result4.data.data;
      this.alltasks = result5.data.data;
      this.branches = result1.data.data;
      this.id = Number(result6.data.data[0].count) + Number(1);
    }
    const result7 = await axios.get("/projects/allcontractors");
    // const result4 = await axios.get("/data/allprojects");

    if (result7.status == 200) {
      // this.allprojects = result4.data.data;
      this.allcontractors = result7.data.data
    }
  },
  data() {
    return {
      disabled: false,
      v$: useValidate(),
      id: "",
      data: [{}],
      number: "",
      date: "",
      description: "",
      customers: [],
      accountpayment: [],
      selection: false,
      customerid: "",
      customername: "",
      customerdata: "",
      totalamount: 0,
      totalreceived: 0,
      totalpaid: 0,
      addto: "",
      unpaidinvoice: [],
      alltasks: [],
      branches: [],
      taskno: "",
      projectno: "",
      branchno: "",
      duedate: "",
      supplydate: "",
      accountno: "",
      account: "",
      accountnamear: "",
      accountnameen: "",
      type: "paid",
      amount: "",
      change: "",
      totalremaining: "",
      recremaining: 0,
      unpaid: false,
      cdata: "",
      allprojects: [],
      project: { pid: 'all', name: "all" },
      allcontractors: [],
      contractor: {}
    };
  },
  validations() {
    return {
      date: { required },
      description: { required },
      amount: { required },
      account: { required },
      cdata: { required }
    };
  },
  methods: {
    back() {
      this.$root.$router.push({
        path: "/AdminDashboard/AllSupplierReceipts",
      });
    },
    calc() {
      this.totalpaid = this.unpaidinvoice.reduce(
        (acc, array) => acc + Number(array.amount),
        0
      );
    },
    calc2() {
      for (let index = 0; index < this.unpaidinvoice.length; index++) {
        this.unpaidinvoice[index].amount = 0;
        this.unpaidinvoice[index].change =
          this.unpaidinvoice[index].tat - this.unpaidinvoice[index].payment;
      }
      this.change = this.amount;
      for (let i = 0; i < this.unpaidinvoice.length; i++) {
        const data = this.unpaidinvoice[i].tat - this.unpaidinvoice[i].payment;
        if (this.change >= data) {
          this.unpaidinvoice[i].amount =
            this.unpaidinvoice[i].tat - this.unpaidinvoice[i].payment;
          this.change = this.change - this.unpaidinvoice[i].amount;
          this.unpaidinvoice[i].change =
            Number(this.unpaidinvoice[i].tat) -
            Number(this.unpaidinvoice[i].payment) -
            Number(this.unpaidinvoice[i].amount);
        } else {
          this.unpaidinvoice[i].amount = this.change;
          this.unpaidinvoice[i].change =
            Number(this.unpaidinvoice[i].tat) -
            Number(this.unpaidinvoice[i].payment) -
            Number(this.unpaidinvoice[i].amount);
          break;
        }
      }
      this.totalpaid = this.unpaidinvoice.reduce(
        (acc, array) => acc + Number(array.amount),
        0
      );
      this.totalremaining = this.unpaidinvoice.reduce(
        (acc, array) => acc + Number(array.change),
        0
      );
      this.recremaining = this.amount - this.totalpaid;
    },
    async del(id) {
      this.data.splice(id, 1);
    },
    task() {
      const project = this.alltasks.filter((x) => x.id == this.taskno)[0]
        .projectid;
      this.projectno = project;
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.disabled = true;
        const result = await axios.post("/projects/CreateSupplierReceiptproject", {
          username: store.state.randp.username,
          due: this.date,
          description: this.description,
          cid: this.cdata.sid,
          cname: this.cdata.name,
          cmobile: this.cdata.mobile,
          cmail: this.cdata.email,
          ctax: this.cdata.taxnumber,
          actnum: this.accountno,
          namear: this.accountnamear,
          nameen: this.accountnameen,
          type: this.type,
          detail: JSON.stringify(this.unpaidinvoice),
          amount: this.amount,
          recremaining: this.recremaining,
          remaining: this.totalremaining,
          branch: this.branchno,
          project: this.project.pid,
          projectname: this.project.name,
          ownerid: this.project.ownerid,
          ownername: this.project.ownername,
          task: this.taskno,
          notes: this.notes,
          ptype: this.project.ptype,
          contractorid: this.contractor.cid,
          contractorname: this.contractor.name
        });
        if (result.status == 200) {
          this.redirectTo({ val: "AllSupplierReceiptsproject" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    async cdata() {
      const result3 = await axios.get(`/data/delsupplier/${this.cdata.sid}`);

      if (result3.status == 200) {
        if (result3.data.data.length == 0) {
          this.unpaid = true;
        }
        this.customerdata = result3.data.data[0];
        for (let index = 0; index < this.unpaidinvoice.length; index++) {
          this.unpaidinvoice[index].amount = 0;
        }
        this.totalamount = this.unpaidinvoice.reduce(
          (acc, array) => acc + Number(array.tat),
          0
        );
        this.totalreceived = this.unpaidinvoice.reduce(
          (acc, array) => acc + Number(array.payment),
          0
        );
      }
    },
    addto() {
      this.projectno = null;
      this.taskno = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 2%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.ccenter button {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.part1 {
  display: flex;
  width: 100%;
}

.part11 {
  width: 50%;
  padding: 2%;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
