<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/tasks" class="active">{{ $t("management") }} {{ $t("tasks") }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("tasks") }}</h3>
      </div>
      <div class="head">
        <div class="head1">
          <!-- <input type="text" :placeholder="$t('name')" v-model="name" /> -->
          <!-- <input type="text" :placeholder="$t('email')" v-model="email" /> -->
          <input type="text" :placeholder="$t('code')" v-model="code" />
          <!-- <input type="text" :placeholder="$t('mobile')" v-model="mobile" /> -->
        </div>
        <div class="head2">
          <button>
            <router-link to="/tasks/CreateNewTask" class="btn-download">
              <span class="text">
                <i class="bi bi-clipboard-plus-fill"></i>
                {{ $t("add") }} {{ $t("tasks") }} {{ $t("new") }}</span>
            </router-link>
          </button>
          <button class="btn-download" v-if="this.checklist.length > 0" @click="submite">استلام الطلبات</button>
        </div>
      </div>
      <div class="body">
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th>{{ $t("code") }}</th>
              <th>{{ $t("type") }}</th>
              <th>{{ $t("assign") }}</th>
              <th>{{ $t("project") }}</th>
              <th>{{ $t("date") }}</th>
              <th>{{ $t("time") }}</th>
              <th>{{ $t("status") }}</th>
              <th>{{ $t("description") }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="val in data" :key="val">
              <td><input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                  v-if="val.status == 'waiting' && typeofuser != 'customer'" v-model="val.check" @change="add(val)">
              </td>
              <td>
                <router-link :to="`/tasks/taskview/${val.tid}`">
                  {{ val.tid }}</router-link>
              </td>
              <td>
                {{ $t(val.type) }}
              </td>
              <td>
                <router-link :to="`/tasks/taskview/${val.tid}`">
                  {{ $t(val.assign) }}</router-link>
              </td>
              <td>
                {{ val.proname }}
              </td>
              <td>
                {{ val.date }}
              </td>
              <td>
                {{ val.time }}
              </td>
              <td>
                {{ val.status }}
              </td>
              <td>
                {{ val.description }}
              </td>
              <th> <button @click="del(val.tid)" v-if="val.status == 'waiting'">
                  <i class="bi bi-trash3-fill"></i>
                </button></th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  props: ["css"],
  data() {
    return {
      name: "",
      email: "",
      mobile: "",
      code: "",
      data: [],
      checklist: [],
      typeofuser: store.state.typeofuser
    };
  },
  async mounted() {
    const result = await axios.get(`/data/getalltasks`);
    if (result.status == 200) {
      this.data = result.data.data;
      this.data2 = result.data.data;
    }
  },
  methods: {
    InvoiceView(row) {
      // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
      this.$root.$router.push({
        path: `/AdminDashboard/InvoiceView/${row.invid}`,
        params: { id: row.id },
      });
    },
    add() {
      this.checklist = this.data2.filter((x) =>
        x.check == true
      );
    },
    async submite() {
      for (let i = 0; i < this.checklist.length; i++) {
        const id = this.checklist[i].tid
        const element = JSON.parse(this.checklist[i].details);
        var data2 = []
        for (let x = 0; x < element.length; x++) {
          const element2 = element[x]
          data2.push({
            namear: element2.namear,
            nameen: element2.nameen,
            sn: element2.sn,
            unittype: element2.unittype,
            quantity: element2.quantity,
            comment: element2.comment,
            stock: 0,
            purchases: 0,
          });
        }
        await axios.post("/data/sendpurchaseorder", {
          id: id,
          assign: "purchases",
          data: JSON.stringify(data2),
          username: store.state.randp.username,
        });
      }
      this.$router.go();
    },
    del(id) {
      Swal.fire({
        title: this.$t("delete") + " " + this.$t("task"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/deletetask", {
            id: id,
            username: store.state.randp.username,
          });
          if (action.status == 200) {
            Swal.fire(this.$t("delete") + " " + this.$t("task"));
            this.redirectTo({ val: "taskshome" });
          }
        }
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    name() {
      this.table.rows = this.table.data.filter((x) =>
        x.name.includes(this.name.toLowerCase())
      );
    },
    mobile() {
      this.table.rows = this.table.data.filter((x) =>
        x.mobile.includes(this.mobile)
      );
    },
    code() {
      this.table.rows = this.table.data.filter((x) => x.id == this.code);
      if (this.code == "") {
        this.table.rows = this.table.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .page {
    // direction: v-bind("css.EN");
    padding: 0.5%;
    font-size: x-small;
  }

  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
