<template>
    <div class="page">
        <div class="head-title">
            <div class="left">
                <ul class="breadcrumb">
                    <li>
                        <a href="#">{{ $t("purchases") }}</a>
                    </li>
                    <li>-</li>
                    <li>
                        <a class="active" href="#">اوامر نقل المنتجات</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="main">
            <div class="head">
                <div class="head1">
                    <h3>اوامر نقل المنتجات للمشروعات</h3>
                    <!-- <input type="text" :placeholder="$t('owner')" v-model="owner" />
                    <input type="text" :placeholder="$t('supplier')" v-model="supplier" /> -->
                    <input type="text" :placeholder="$t('project')" v-model="project" />
                    <input type="date" :placeholder="$t('from')" v-model="datefrom" />
                    <input type="date" :placeholder="$t('to')" v-model="dateto" />
                    <select name="" id="" v-model="status" style="border: none;">
                        <option value="%">{{ $t("all") }}</option>
                        <option value="waiting">{{ $t("waiting") }}</option>
                        <option value="confirmed">{{ $t("confirmed") }}</option>
                        <option value="deleted">{{ $t("deleted") }}</option>
                    </select>
                    <button style="padding: 1%" @click="search()">
                        {{ $t("search") }}
                    </button>
                </div>
                <div class="head2">
                    <button>
                        <router-link to="/ProjectsManagement/AllProductTransfer/CreateProductTransfer"
                            class="btn-download">
                            <span class="text">
                                <i class="bi bi-clipboard-plus-fill"></i>
                                اضافة امر نقل منتجات جديد</span>
                        </router-link>
                    </button>
                    <!-- <button @click="exportToExcel()">Excel</button> -->
                </div>
            </div>
            <div class="body">
                <div>
                    <table-lite :is-static-mode="true" :columns="table.columns" :rows="table.rows"
                        :total="table.totalRecordCount" :sortable="table.sortable" @row-delete="rowDelete"
                        @row-edite="eslam" @row-view="InvoiceView"></table-lite>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, computed } from "vue";
import TableLite from "../../components/TableLite.vue";
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import * as XLSX from "xlsx";
export default {
    title: "Bills",
    props: ["css"],
    components: { TableLite },
    data() {
        return {
            name: "",
            email: "",
            mobile: "",
            code: "",
            r7: JSON.parse(store.state.randp.r7),
            invnum: "",
            owner: "",
            supplier: "",
            project: "",
            datefrom: "",
            dateto: "",
            status: "%"
        };
    },
    async mounted() {
        if (this.r7.create != true) {
            this.redirectTo({ val: "Home" });
        }
    },
    setup() {
        // Table config
        const table = reactive({
            columns: [
                {
                    label: "code",
                    field: "sid",
                    width: "1%",
                    text: "TRAN-",
                    sortable: true,
                    isKey: true,
                    headerClasses: ["bg-gold", "color-red"],
                    columnClasses: ["bg-gray"],
                },
                {
                    label: "dueon",
                    field: "duedate",
                    width: "15%",
                    sortable: true,
                },
                {
                    label: "project",
                    field: "fromproject",
                    text: "من ",
                    width: "15%",
                    sortable: true,
                },
                {
                    label: "project",
                    field: "toproject",
                    text: "الى ",
                    width: "15%",
                    sortable: true,
                },
                {
                    label: "description",
                    field: "description",
                    width: "10%",
                    sortable: true,
                },
                {
                    label: "total",
                    field: "tat",
                    width: "10%",
                    sortable: true,
                },
                {
                    label: "status",
                    field: "review",
                    width: "10%",
                    sortable: true,
                },
                {
                    label: "option",
                    // headerClasses: ["bg-gold"],
                    // columnClasses: ["bg-gray"],
                    // columnStyles: { background: "gray" },
                    field: "quick",
                    width: ".5%",
                    display: function () {
                        return `<button class="btn btn-light"><i class="bi bi-search"></i></button>`;
                    },
                },
            ],
            rows: [],
            data: [],
            totalRecordCount: computed(() => {
                return table.rows.length;
            }),
            sortable: {},
        });
        const rowDelete = async (row) => {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const del = await axios.delete(`/projects/delprojecttransferproduct/${row.sid}`);
                    if (del.status == 200) {
                        const indexOfObject = table.rows.findIndex((object) => {
                            return object.id === row.id;
                        });
                        table.rows.splice(indexOfObject, 1);
                        Swal.fire("Deleted!", "Your file has been deleted.", "success");
                    }
                }
            });
        };
        const rowEdit = () => {
            // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
        };
        const rowView = (row) => {
            console.log("Row rowView!", row);
        };
        /**
         * Table search event
         */
        const doSearch = (offset, limit, order, sort) => {
            table.isLoading = true;

            // Start use axios to get data from Server
            let url = "/projects/allproducttransprojects";
            axios.get(url).then((response) => {
                table.rows = response.data.data;
                table.data = response.data.data;
                table.totalRecordCount = response.data.data.length;
                table.sortable.order = order;
                table.sortable.sort = sort;
            });
        };

        // Get data first
        doSearch(0, 10, "id", "asc");
        return {
            table,
            rowDelete,
            rowEdit,
            rowView,
        };
    },
    methods: {
        // InvoiceView(row) {
        //     this.$root.$router.push({
        //         path: `/ProjectsManagement/ProjectsBillView/${row.bid}`,
        //         params: { id: row.id },
        //     });
        // },
        async search() {
            // const action = await axios.post(`/projects/albills`, {
            //     id: this.code,
            //     datefrom: this.datefrom,
            //     dateto: this.dateto,
            //     ownername: this.owner,
            //     sname: this.supplier,
            //     project: this.project,
            //     status: this.status,
            //     invnum: this.invnum
            // });
            // if (action.status == 200) {
            //     this.table.rows = action.data.data;
            // }
        },
        exportToExcel() {
            var data = [];
            for (let i = 0; i < this.table.rows.length; i++) {
                const name = this.table.rows[i];
                data.push({
                    رقم_الفاتورة: name.bid,
                    المالك: name.cname,
                    المشروع: name.project,
                    المورد: name.sname,
                    المبلغ: Number(name.tat),
                    رقم_الفاتورة_الضريبية: name.invnum,
                    التاريخ: name.due,
                    الحالة: name.review
                });
            }
            // Create a new workbook
            const wb = XLSX.utils.book_new();
            // Convert data to a worksheet
            const ws = XLSX.utils.json_to_sheet(data);
            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            // Generate a download link to the Excel file
            XLSX.writeFile(wb, `المشتريات.xlsx`);
        },
        ...mapActions(["redirectTo"]),
    },
    watch: {
        // name() {
        //   this.table.rows = this.table.data.filter((x) =>
        //     x.name.includes(this.name.toLowerCase())
        //   );
        // },
        // mobile() {
        //   this.table.rows = this.table.data.filter((x) =>
        //     x.mobile.includes(this.mobile)
        //   );
        // },
        // code() {
        //   this.table.rows = this.table.data.filter((x) => x.id == this.code);
        //   if (this.code == "") {
        //     this.table.rows = this.table.data;
        //   }
        // },
        // invnum() {
        //   this.table.rows = this.table.data.filter((x) =>
        //     x.invnum.includes(this.invnum)
        //   );
        // }
    },
};
</script>
<style lang="scss" scoped>
.page {
    direction: v-bind("css.EN");
    padding: 0.5%;
}

.main {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1%;
}

.body {
    padding: 1%;
    // min-height: 500px;
    // border: 1px solid black;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part {
    font-size: large;
    width: 99%;
    padding: 2%;
    margin: 0.5%;
    background-color: white;
    border-radius: 5px;
    color: black;
    display: flex;
    // border: 1px solid black;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part .group {
    width: 50%;
    //   border: black 1px solid;
}

.part img {
    width: 100%;
    height: 100px;
    //   border: black 1px solid;
}

ul {
    text-decoration: none;
    list-style-type: none;
    color: black;
}

.bi {
    margin: 1%;
}

.head {
    padding: 0.25%;
    display: flex;
    border-bottom-style: ridge;
}

.head1 {
    width: 60%;
}

.head1 input {
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
}

.head2 {
    width: 40%;
    text-align: center;
}

.head2 button {
    margin: 0.5%;
    padding: 1%;
    border: none;
    border-radius: 5px;
    background-color: rgba(98, 153, 174, 255);
    color: white;
    font-size: small;
    width: 30%;
}

.head2 button a {
    color: white;
}
</style>