<template>
    <div class="page">
        <div class="head-title">
            <div class="left">
                <ul class="breadcrumb">
                    <li>
                        <router-link to="/purchases/home" class="active">{{
                            $t("purchases")
                            }}</router-link>
                    </li>
                    <li>-</li>
                    <li>
                        <router-link to="/purchases/AllBills" class="active">{{
                            $t("bills")
                            }}</router-link>
                    </li>
                    <li>-</li>
                    <li>
                        <a class="active" href="#">
                            {{ $t("bills") }} - Bill-{{ alldata2.bid }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="main">
            <div class="head">
                <div class="head1">
                    <h3>
                        <h3>{{ $t("bills") }} - Bill-{{ alldata2.bid }}</h3>
                    </h3>
                </div>
                <div class="head2">
                    <button class="buttonconfirm" type="submit" @click="confirm" v-if="alldata2.review == 'waiting'">
                        <i class="bi bi-file-check-fill"></i> {{ $t("confirm") }}
                    </button>
                    <button class="buttonconfirm" type="submit" @click="print2">
                        <i class="bi bi-printer-fill"></i> {{ $t("printer") }}
                    </button>
                    <button class="buttonconfirm" type="submit" @click="pdf">
                        <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
                    </button>

                    <span v-if="alldata2.review == 'confirmed'&& alldata2.fai_invoice_preview_links">
                        <a :href="alldata2.fai_invoice_preview_links" target="_blank" class="buttonconfirm" title="Download">
                            فاتورة هيئة الزكاه
                        </a>
                    </span>

                    <button class="buttoncancel" type="submit" @click="back">
                        {{ $t("cancel") }}
                    </button>
                </div>
            </div>
            <div class="body">
                <div class="part1">
                    <div class="part11">
                        <div class="row mb-2">
                            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("number") }}
                            </label>
                            <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                    Bill-{{ alldata2.bid }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2" v-if="alldata2.ord != null">
                            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("order") }} {{ $t("purchase")
                                }}
                            </label>
                            <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                    ORD-{{ alldata2.ord }}
                                </div>
                            </div>
                        </div>
                        <!--  -->
                        <div class="row mb-2">
                            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }}
                            </label>
                            <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                    {{ alldata2.due }}
                                </div>
                            </div>
                        </div>
                        <!--  -->
                        <div class="row mb-2">
                            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("description") }}
                            </label>
                            <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                    {{ alldata2.description }}
                                </div>
                            </div>
                        </div>
                        <!--  -->
                        <div class="row mb-2">
                            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }} {{ $t("due") }}
                            </label>
                            <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                    {{ alldata2.duedate }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("supplier") }}
                            </label>
                            <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                    {{ alldata2.sname }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("number") }} {{ $t("invoice")
                                }}
                            </label>
                            <div class="col-sm-7">
                                <div class="form-control form-control-sm">
                                    {{ alldata2.invnum }}
                                </div>
                            </div>
                        </div>
                        <!--  -->
                    </div>
                    <div class="part11">
                        <h4>{{ $t("data") }} {{ $t("project") }}</h4>

                        <div class="row mb-2">
                            <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                                {{ $t("name") }} {{ $t("project") }}
                            </label>
                            <div class="col-sm-7">
                                {{ alldata2.project }}
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                                {{ $t("name") }} {{ $t("owner") }}
                            </label>
                            <div class="col-sm-7">
                                {{ alldata2.cname }}
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                                {{ $t("type") }} {{ $t("project") }}
                            </label>
                            <div class="col-sm-7">
                                {{ alldata2.cmail }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="part2">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th style="width: 12%">{{ $t("product") }}</th>
                                <th style="width: 12%">{{ $t("classification") }}</th>
                                <th>{{ $t("description") }}</th>
                                <th style="width: 10%">{{ $t("quantity") }}</th>
                                <th style="width: 10%">{{ $t("unit type") }}</th>
                                <th>{{ $t("price") }}</th>
                                <th>{{ $t("total") }} {{ $t("befor") }} {{ $t("tax") }}</th>
                                <th>{{ $t("tax") }}</th>
                                <th>{{ $t("amount") }} {{ $t("tax") }}</th>
                                <th>{{ $t("total") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, id) in details" :key="value">
                                <td>{{ id + 1 }}</td>
                                <td>{{ value.pronamear }}</td>
                                <td>{{ value.classname }}</td>
                                <td>
                                    {{ value.description }}
                                </td>
                                <td>
                                    {{ value.quantity }}
                                </td>
                                <td>{{ value.unittype }}</td>
                                <td>{{ value.buyp }}</td>
                                <td>{{ value.tbt }}</td>
                                <td>{{ value.tax }} %</td>
                                <td>{{ value.taxamount }}</td>
                                <td>{{ value.total }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="5"></td>
                                <td>{{ $t("total") }}</td>
                                <td>{{ alldata2.tbt }}</td>
                                <td></td>
                                <td>{{ alldata2.tax }}</td>
                                <td>{{ alldata2.tat }}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <hr />
                <div class="total">
                    <h4>{{ $t("total") }}</h4>
                    <div class="total1">
                        المبلغ قبل الضريبة
                        <input type="text" disabled v-model="alldata2.tbt" />
                    </div>
                    <div class="total1">
                        قيمة الضريبة <input type="text" disabled v-model="alldata2.tax" />
                    </div>
                    <div class="total1">
                        بعد الضريبة <input type="text" disabled v-model="alldata2.tat" />
                    </div>
                </div>
                <hr />
                <div class="row mb-2">
                    <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("notes") }}
                    </label>
                    <div class="col-sm-5">
                        <div class="form-control" id="exampleFormControlTextarea1">
                            {{ alldata2.notes }}
                        </div>
                    </div>
                </div>

                <hr />
                <div class="user">
                    <i class="bi bi-eye-fill"></i> &nbsp; {{ $t("created") }}
                    {{ $t("by") }} : {{ alldata2.username }}
                    <div class="udate">
                        {{ $t("date") }} : {{ alldata2.date }} - {{ $t("time") }} :
                        {{ alldata2.time }}
                    </div>
                </div>
                <div class="user" v-if="alldata2.review == 'confirmed'">
                    <i class="bi bi-eye-fill"></i> &nbsp; {{ $t("confirmed") }}
                    {{ $t("by") }} : {{ alldata2.reviewusername }}
                    <div class="udate">
                        {{ $t("date") }} : {{ alldata2.reviewdate }} - {{ $t("time") }} :
                        {{ alldata2.reviewtime }}
                    </div>
                </div>
                <div class="user" v-if="alldata2.review == 'cancel'">
                    <i class="bi bi-eye-fill"></i> &nbsp; {{ $t("cancel") }}
                    {{ $t("by") }} : {{ alldata2.reviewusername }}
                    <div class="udate">
                        {{ $t("date") }} : {{ alldata2.reviewdate }} - {{ $t("time") }} :
                        {{ alldata2.reviewtime }}
                    </div>
                </div>
            </div>
            <br />
        </div>
        <div id="main" style="padding: 1%; display: none">
            <div :style="{ direction: css.EN }">
                <img :src="this.url" crossorigin="anonymous" style="height: 100px; width: 50%" />
                <div class="part1" style="clear: both">
                    <div class="part11" style="display: flex">
                        {{ $t("number") }} :
                        {{ alldata2.id }}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {{ $t("date") }} :
                        {{ alldata2.due }}
                        <br />
                        {{ $t("description") }} :
                        {{ alldata2.description }}
                        <br />
                    </div>
                    <br />
                    <div class="part12">
                        <h4>{{ $t("data") }} {{ $t("customer") }}</h4>
                        <div style="padding: 2%">
                            {{ $t("name") }} :
                            {{ alldata2.cname }}
                            <br />
                            {{ $t("mobile") }} :
                            {{ alldata2.cmobile }}
                            <br />
                            {{ $t("email") }} :
                            {{ alldata2.cmail }}
                            <br />
                            {{ $t("tax") }} :
                            {{ alldata2.ctax }}
                        </div>
                    </div>
                </div>
                <br /><br />
                <div class="part2">
                    <table style="width: 100%; text-align: center; font-size: small">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th style="width: 12%">{{ $t("product") }}</th>
                                <th>{{ $t("description") }}</th>
                                <th style="width: 10%">{{ $t("quantity") }}</th>
                                <th style="width: 10%">{{ $t("unit type") }}</th>
                                <th>{{ $t("price") }}</th>
                                <th>{{ $t("discount") }}</th>
                                <th>{{ $t("total") }} {{ $t("befor") }} {{ $t("tax") }}</th>
                                <th>{{ $t("tax") }}</th>
                                <th>{{ $t("amount") }} {{ $t("tax") }}</th>
                                <th>{{ $t("total") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, id) in details" :key="value">
                                <td>{{ id + 1 }}</td>
                                <td>{{ value.pronamear }}</td>
                                <td>
                                    {{ value.description }}
                                </td>
                                <td>
                                    {{ value.quantity }}
                                </td>
                                <td>{{ value.unittype }}</td>
                                <td>{{ value.salep }}</td>
                                <td>{{ value.discount }} %</td>
                                <td>{{ value.tbt }}</td>
                                <td>{{ value.tax }} %</td>
                                <td>{{ value.taxamount }}</td>
                                <td>{{ value.total }}</td>
                            </tr>
                        </tbody>

                        <tfoot>
                            <tr>
                                <td colspan="11">
                                    <hr />
                                </td>
                            </tr>
                            <tr>
                                <td colspan="6"></td>
                                <td>{{ $t("total") }}</td>
                                <td>{{ alldata2.tbt }}</td>
                                <td></td>
                                <td>{{ alldata2.tax }}</td>
                                <td>{{ alldata2.tat }}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <hr />

                {{ $t("notes") }} :
                {{ alldata2.notes }}
                <hr />
            </div>
        </div>
    </div>
</template>
<script>
import store from "@/store";
// import useValidate from "@vuelidate/core";
// import { required } from "@vuelidate/validators";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import html2pdf from "html2pdf.js";
export default {
  async mounted() {
    const data2 = await axios.get(`/data/billview/${this.$route.params.id}`);
    if (data2.status == 200) {
      const data = data2.data.data[0];
      this.alldata2 = data;
      this.details = JSON.parse(this.alldata2.details);
      this.totaldpt = this.details.reduce(
        (acc, array) => acc + Number(array.dpt),
        0
      );
      this.totalcrd = this.details.reduce(
        (acc, array) => acc + Number(array.crd),
        0
      );
      this.url =
        store.state.imgurl + `/clogo/${this.$store.state.cnum[0].cnum}.png`;
    }
  },
  props: ["css"],
  data() {
    return {
      alldata2: "",
      details: [],
      fileurl: [],
      url: "",
    };
  },
  methods: {
    confirm() {
      Swal.fire({
        title:
          this.$t("confirm") + " " + this.$t("bills") + " " + this.alldata2.bid,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/projects/confirmbill", {
            id: this.alldata2.bid,
            username: store.state.randp.username,
          });
          if (action.status == 200) {
            Swal.fire(
              this.$t("done") +
              " " +
              this.$t("confirm") +
              " " +
              this.$t("bills") +
              " - " +
              this.alldata2.bid
            );
            this.redirectTo({ val: "AllProjectsBills" });
          }
        }
      });
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.2,
        filename: "barcode.pdf",
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    print() {
      this.$htmlToPaper("main");
    },
    print2() {
      this.$htmlToPaper("main");
    },
    ...mapActions(["redirectTo"]),
  },
};
</script>
<style lang="scss" scoped>
.page {
    // direction: v-bind("css.EN");
    padding: 0.5%;
}

.main {
    width: 98%;
    margin: 1%;
    padding: 1%;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    border: ridge 0.5px;
}

.head {
    padding: 0.25%;
    display: flex;
    border-bottom-style: ridge;
}

.head1 {
    width: 50%;
}

input {
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
    text-align: center;
}

.head2 {
    width: 50%;
    text-align: center;
}

button {
    margin: 0.5%;
    padding: 2%;
    border: none;
    border-radius: 5px;
    background-color: rgba(98, 153, 174, 255);
    color: white;
}

.ccenter button {
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
}

.head2 button a {
    color: white;
}

.table {
    margin-top: 1%;
}

table thead tr {
    border-radius: 5px;
    background-color: #eee;
}

select {
    width: 15%;
    margin: 0.5%;
    // padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
}

.part1 {
    display: flex;
    width: 100%;
}

.part11 {
    width: 50%;
    padding: 2%;
}

@media screen and (max-width: 576px) {
    .table {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        background-color: white;
        font-size: xx-small;
    }
}
</style>
