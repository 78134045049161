<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <router-link to="/tasks" class="active">{{ $t("management") }} {{ $t("tasks") }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="" class="active">
              {{ $t("requests") }} {{ $t("purchases") }} -
              {{ this.$route.params.id }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>
            {{ $t("request") }} {{ $t("purchases") }}
            {{ this.$route.params.id }}
          </h3>
        </div>
        <div class="head2">
          <button type="submit" @click="edite">
            {{ $t("Edite") }}
          </button>
          <!-- <button type="submit" @click="pdf">
            <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
          </button> -->
          <button type="submit" @click="exportToExcel"><i class="bi bi-file-earmark-excel"></i> Excel </button>
        </div>
      </div>
      <div class="body">
        <div class="part1"></div>
      </div>
      <div class="body2">
        <div>
          <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-2 col-form-label">{{ $t("date") }}
            </label>

            {{ date }}
          </div>
          <div class="row mb-3">
            <label for="inputPassword3" class="col-sm-2 col-form-label">{{ $t("time") }}
            </label>

            {{ time }}
          </div>
          <!-- <div class="row mb-2">
            <label for="inputPassword3" class="col-sm-2 col-form-label">{{ $t("ccenter") }}
            </label>
            <div class="col-sm-5" style="display: flex">
              {{ costname }}
            </div>
          </div> -->
          <div class="row mb-2">
            <label for="inputPassword3" class="col-sm-2 col-form-label"> {{ $t("number") }} {{ $t("invoice") }}
            </label>
            <div class="col-sm-3">
              <input type="number" v-model="inv" />
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputPassword3" class="col-sm-2 col-form-label"> {{ $t("supplier") }}
            </label>
            <div class="col-sm-3">
              <v-select class="select" :options="allsupplier" label="name" v-model="supplier">
                <template #option="option">
                  <div style="text-align: center">
                    <span>{{ option.name }}</span>
                  </div>
                </template>
              </v-select>
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputPassword3" class="col-sm-2 col-form-label"> {{ $t("item") }}
            </label>
            <div class="col-sm-3">
              <v-select class="select" :options="catigories" label="category" v-model="catigory">
                <template #option="option">
                  <div style="text-align: center">
                    <span>{{ option.category }}</span>
                  </div>
                </template>
              </v-select>
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputPassword3" class="col-sm-2 col-form-label"> {{ $t("product") }}
            </label>
            <div class="col-sm-3">
              <v-select :options="fproducts" label="namear" v-model="product">
                <template #option="option">
                  <div style="text-align: center">
                    <span>{{ option.namear }} - {{ option.barcode }} -
                      {{ option.unittype }}
                    </span>
                  </div>
                </template>
              </v-select>
              <br />
              <button class="buttonconfirm" @click="add">{{ $t("add") }}</button>
            </div>

          </div>
          <div style="overflow: auto;min-height: 300px">
            <table class="table">
              <thead>
                <tr>
                  <td colspan="16">{{ $t("details") }} {{ $t("materials") }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>{{ $t("number") }} {{ $t("invoice") }}</td>
                  <td>{{ $t("materials") }}</td>
                  <td>{{ $t("unit") }}</td>
                  <td>
                    {{ $t("quantity") }}
                  </td>
                  <!-- <td>{{ $t("request") }} {{ $t("purchase") }}</td> -->
                  <td>{{ $t("suppliers") }}</td>
                  <td>{{ $t("quantity") }} {{ $t("purchases") }}</td>
                  <td>{{ $t("type") }} {{ $t("payment") }}</td>

                  <td>{{ $t("price") }} {{ $t("unit") }}</td>
                  <td>{{ $t("tax") }}</td>
                  <td>{{ $t("total") }}</td>
                  <td></td>
                  <!-- <td>1</td>
                  <td>2</td>
                  <td>3</td>
                  <td>4</td> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(x, id) in data2" :key="x">
                  <td>{{ id + 1 }}</td>
                  <td>
                    <input type="number" v-model="x.inv" />

                  </td>
                  <td>{{ x.name }} - {{ x.sn }}</td>

                  <td>
                    {{ x.unittype }}
                  </td>
                  <td>
                    <input type=" number" v-model="x.quantity" />

                  </td>
                  <!-- <td>
                    {{ x.purchase }}
                  </td> -->
                  <td>
                    <v-select class="select" :options="allsupplier" label="name" v-model="x.supplier">
                      <template #option="option">
                        <div style="text-align: center">
                          <span>{{ option.name }}</span>
                        </div>
                      </template>
                    </v-select>
                  </td>
                  <td>
                    <input type="number" v-model="x.qpurchases" />
                  </td>
                  <td>
                    <select v-model="x.typepay">
                      <option value="كاش">كاش</option>
                      <option value="اجل">اجل</option>
                    </select>
                  </td>

                  <td>
                    <input type="number" v-model="x.price" />
                  </td>
                  <!-- <td >
                    <input type="text" v-model="x.tax" />
                  </td> -->
                  <td>
                    <select v-model="x.tax">
                      <option value="true">يوجد</option>
                      <option value="false">لا يوجد</option>
                    </select>
                  </td>
                  <td>
                    <span v-if="x.tax == 'true'">{{
                      ((
                        Number(x.price) +
                        Number(Number(x.price) * 15) / 100
                      ) * x.qpurchases).toFixed(2)
                    }}</span>
                    <span v-if="x.tax == 'false'">{{
                      Number(x.price * x.qpurchases).toFixed(2)
                    }}</span>
                  </td>
                  <td>
                    <a href="#" @click.prevent="del(id)"><i class="bi bi-x-circle-fill"></i></a>
                  </td>
                  <!-- <td>
                    <input type="number" v-model="x.one" />

                  </td>
                  <td>
                    <input type="number" v-model="x.two" />

                  </td>
                  <td>
                    <input type="number" v-model="x.three" />

                  </td>
                  <td>
                    <input type="number" v-model="x.four" />

                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <button @click="purchaseorder" style="padding: 1%" v-if="dep == 'purchases' && status == 'waiting'">
            انهاء الطلب
          </button>
          <hr />
          <div class="activity">
            <ul v-for="x in activity" :key="x">
              <li style="width: 150px">
                {{ x.action }} <br />
                <span style="font-size: x-small">{{ x.date }} - {{ x.time }}</span>
                <br v-if="x.comment" />{{ x.comment }} <br />{{ x.username }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import html2pdf from "html2pdf.js";
import * as XLSX from 'xlsx';
export default {
  components: {
    vSelect,
  },
  props: ["css"],
  data() {
    return {
      date: "",
      time: "",
      assign: "",
      mobile: "",
      code: "",
      data: [],
      data2: [],
      type: "supplyorder",

      parent: "",
      parent2: "",
      level1: [],
      allsupplier: [],
      branches: [],
      costn: "",
      costname: "",
      address: "",
      activity: "",
      status: "",
      dep: store.state.dep,
      reassign: "",
      comment: "",
      products: [],
      fproducts: [],
      product: "",
      supplier: {},
      catigories: "",
      catigory: {},
      inv: ""
    };
  },
  validations() {
    return {};
  },
  async mounted() {
    const result2 = await axios.get(
      `/data/supplyorderwait/${this.$route.params.id}`
    );
    if (result2.status == 200) {
      const data = result2.data.data[0];
      // const data2 = JSON.parse(data.orders);
      this.data2 = JSON.parse(data.orders);
      this.alldata = data;
      this.type = data.type;
      this.assign = data.assign;
      this.date = data.date;
      this.time = data.time;
      this.costname = data.costname;
      this.address = data.address;
      this.status = data.status;
      // this.activity = result2.data.data2;
    }
    const result = await axios.get(`/data/allsupplier`);
    if (result.status == 200) {
      this.allsupplier = result.data.data;
    }
    const result3 = await axios.get(`/data/allproducts`);
    if (result3.status == 200) {
      this.products = result3.data.data;
      this.fproducts = result3.data.data;
    }
    const result4 = await axios.get(`/data/allproductcategories`);
    if (result4.status == 200) {
      this.catigories = result4.data.data;
    }
  },
  methods: {
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.2,
        filename: "JournalReport.pdf",
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    exportToExcel() {
      var data = [];
      for (let i = 0; i < this.data2.length; i++) {
        const name = this.data2[i]
        if (name.tax == 'true') {
          data.push({
            رقم_الفاتورة: name.inv,
            المواد: name.name,
            كود: name.sn,
            الوحدة: name.unittype,
            الكمية: name.quantity,
            الموردين: name.supplier.name,
            كمية_المشتريات: name.qpurchases,
            نوع_السداد: name.typepay,
            سعر_الوحدة: name.price,
            الضريبة: name.tax,
            المبلغ_شامل_الضريبة: ((
              Number(name.price) +
              Number(Number(name.price) * 15) / 100
            ) * name.qpurchases).toFixed(2),
            تفاصيل_1: name.one,
            تفاصيل_2: name.two,
            تفاصيل_3: name.three,
            تفاصيل_4: name.four
          })
        }
        if (name.tax == 'false') {
          data.push({
            رقم_الفاتورة: name.inv,
            المواد: name.name,
            كود: name.sn,
            الوحدة: name.unittype,
            الكمية: name.quantity,
            الموردين: name.supplier.name,
            كمية_المشتريات: name.qpurchases,
            نوع_السداد: name.typepay,
            سعر_الوحدة: name.price,
            الضريبة: name.tax,
            المبلغ_شامل_الضريبة: Number(name.price * name.qpurchases).toFixed(2),
            تفاصيل_1: name.one,
            تفاصيل_2: name.two,
            تفاصيل_3: name.three,
            تفاصيل_4: name.four
          })
        }

      }
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Convert data to a worksheet
      const ws = XLSX.utils.json_to_sheet(data);
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      // Generate a download link to the Excel file
      XLSX.writeFile(wb, 'PO.xlsx');
    },
    purchaseorder() {
      Swal.fire({
        title:
          this.$t("send") +
          " " +
          this.$t("request") +
          " " +
          this.$t("financial"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/sendfinancialrequest", {
            id: this.$route.params.id,
            assign: "financial",
            data: JSON.stringify(this.data2),
            username: store.state.randp.username,
          });
          if (action.status == 200) {
            Swal.fire(this.$t("send") + " " + this.$t("task"));
            this.redirectTo({ val: "taskshome" });
          }
        }
      });
    },
    edite() {
      Swal.fire({
        title: this.$t("Edite") + " " + this.$t("PO"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/editepo", {
            id: this.$route.params.id,
            data: JSON.stringify(this.data2),
            username: store.state.randp.username,
          });
          if (action.status == 200) {
            Swal.fire(this.$t("send") + " " + this.$t("task"));
            this.redirectTo({ val: "taskshome" });
          }
        }
      });
    },
    reassignaction() {
      Swal.fire({
        title: "اعادة توجية المهمة",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/reassigntask", {
            id: this.$route.params.id,
            assign: this.reassign,
            username: store.state.randp.username,
            comment: this.comment,
          });
          if (action.status == 200) {
            Swal.fire(this.$t("send") + " " + this.$t("task"));
            this.redirectTo({ val: "taskshome" });
          }
        }
      });
    },
    reseivetask() {
      Swal.fire({
        title:
          this.$t("send") +
          " " +
          this.$t("request") +
          " " +
          this.$t("financial"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.post("/data/reseivetask", {
            id: this.$route.params.id,
            assign: this.reassign,
            username: store.state.randp.username,
            comment: this.comment,
          });
          if (action.status == 200) {
            Swal.fire(this.$t("send") + " " + this.$t("task"));
            this.redirectTo({ val: "AllTasksView" });
          }
        }
      });
    },
    async del(id) {
      this.data2.splice(id, 1);
      this.calc();
    },
    add() {
      this.data2.unshift({
        name: this.product.namear,
        nameen: this.product.nameen,
        sn: this.product.sn,
        unittype: this.product.unittype,
        quantity: 0,
        supplier: this.supplier,
        inv: this.inv
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    parent() {
      this.level2 = this.branches.filter(
        (x) =>
          x.actnum.startsWith(this.parent.actnum) &&
          x.actnum != this.parent.actnum
      );
      this.costname = this.parent.namear;
      this.costn = this.parent.actnum;
    },
    parent2() {
      this.costname = this.parent2.namear;
      this.costn = this.parent2.actnum;
    },
    ccenter() {
      if (this.ccenter == false) {
        this.costname = "";
        this.costn = "";
      }
    },
    catigory() {
      console.log(this.catigory.category);

      this.fproducts = this.products.filter((x) =>
        x.item == this.catigory.category
      )

    }
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 3%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  // width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.body {
  display: flex;
  margin: 1%;
}

.part1 {
  width: 50%;
}

.body2 {
  width: 100%;
  padding: 1%;
  // border: 1px solid black;
  border-radius: 5px;
  text-align: center;
}

.activity {
  // display: flex;
  margin-top: 2%;
  width: 75%;
  font-size: small;
}

table input {
  text-align: center;
}

@media screen and (max-width: 576px) {
  .page {
    // direction: v-bind("css.EN");
    padding: 0.5%;
    font-size: x-small;

  }

  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
