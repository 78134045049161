<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("sales") }}</a>
          </li>
          <li>-</li>
          <li>
            <a href="#">{{ $t("bills") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ $t("add") }} {{ $t("returns") }} {{ $t("bills") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("add") }} {{ $t("returns") }} {{ $t("bills") }}</h3>
        </div>
        <div class="head2">
          <button class="buttonconfirm" type="submit" :disabled="disabled" @click="submit">
            {{ $t("save") }}
          </button>
        </div>
      </div>
      <div class="body">
        <div class="part1">
          <div class="part11">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("number") }}
              </label>
              <div class="col-sm-7">REBILL-{{ id }}</div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }}
                <span class="error-feedback">{{
                  "*"
                }}</span></label>
              <div class="col-sm-7">
                <input type="date" class="form-control form-control-sm" id="inputEmail3" v-model="date" />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("description") }}
              </label>
              <div class="col-sm-7">
                <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="description" />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("supplier") }} <span
                  class="error-feedback">{{
                    "*"
                  }}</span>
              </label>
              <div class="col-sm-7">
                <v-select :options="customers" label="name" v-model="sdata">
                  <template #option="option">
                    <span>{{ option.name }}</span>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }} {{ $t("due") }}
                <span class="error-feedback">{{
                  "*"
                }}</span></label>
              <div class="col-sm-7">
                <input type="date" class="form-control form-control-sm" id="inputEmail3" v-model="duedate" />
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("number") }} {{ $t("invoice") }}
                <span class="error-feedback">{{
                  "*"
                }}</span></label>
              <div class="col-sm-7">
                <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="invnum" />
              </div>
            </div>
            <!-- <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("date") }} {{ $t("supply") }}
                <span class="error-feedback" v-if="v$.date.$error">{{
                  "*"
                }}</span></label>
              <div class="col-sm-7">
                <input type="date" class="form-control form-control-sm" id="inputEmail3" v-model="supplydate" />
              </div>
            </div> -->
          </div>
          <div class="part12" style="padding: 2%;">
            <h4>{{ $t("data") }} {{ $t("project") }}</h4>

            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("name") }} {{ $t("project") }}
              </label>
              <div class="col-sm-8">
                {{ project.name }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("name") }} {{ $t("owner") }}
              </label>
              <div class="col-sm-8">
                {{ project.ownername }}
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-3 col-form-label-sm">
                {{ $t("type") }} {{ $t("project") }}
              </label>
              <div class="col-sm-8">
                <span v-if="project.ptype == 'inhouse'">مشروعات تحت التنفيذ</span>
                <span v-if="project.ptype == 'other'">مشروعات الغير </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("project") }} <span
              class="error-feedback">{{
                "*"
              }}</span>
          </label>
          <div class="col-sm-4">
            <v-select :options="allprojects" label="name" v-model="project">
              <template #option="option">
                <span>{{ option.name }}</span>
              </template>
            </v-select>
          </div>
        </div>
        <div class="row mb-2">
          <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("product") }}
          </label>
          <div class="col-sm-2">
            <v-select :options="products" label="namear" v-model="product">
              <template #option="option">
                <span>{{ option.barcode }} - {{ option.namear }}</span>
              </template>
            </v-select>
          </div>
          <!-- Button trigger modal -->
          <!-- <div class="col-sm-1">
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
              + منتج جديد</button>
          </div> -->
          <!-- Modal -->
          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-body">
                  ...
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
          <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("classification") }}
          </label>
          <div class="col-sm-2">
            <v-select :options="alltasks" label="name" v-model="thetask">
              <template #option="option">
                <span>{{ option.name }}</span>
              </template>
            </v-select>
          </div>
          <label class="col-sm-1 col-form-label-sm">{{ $t("tax") }}
          </label>
          <div class="col-sm-1">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="cofirmtax" />
          </div>
          <div class="col-sm-1">
            <button class="btn btn-primary" @click="Addproduct" style="padding: 1%">
              {{ $t("add") }} <i class="bi bi-plus"></i>
            </button>
          </div>
        </div>
        <div class="part2">
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th style="width: 3%">{{ $t("product") }}</th>
                <th style="width: 3%">{{ $t("classification") }}</th>
                <th>{{ $t("description") }}</th>
                <th style="width: 10%">{{ $t("quantity") }}</th>
                <th style="width: 10%">{{ $t("unit type") }}</th>
                <th>{{ $t("discount") }}</th>
                <th>{{ $t("price") }}</th>

                <th>{{ $t("total") }} {{ $t("befor") }} {{ $t("tax") }}</th>
                <th>{{ $t("tax") }}</th>
                <th>{{ $t("amount") }} {{ $t("tax") }}</th>
                <th>{{ $t("total") }}</th>
                <th>{{ $t("cancel") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, id) in data" :key="value">
                <td>{{ id + 1 }}</td>
                <td style="width: 12%">
                  <span>{{ value.barcode }} - {{ value.pronamear }}</span>
                </td>
                <td style="width: 12%">
                  <span>{{ value.classname }}</span>
                </td>
                <td>
                  <input class="form-control form-control-sm" type="text" v-model="value.description" />
                </td>
                <td>
                  <input class="form-control form-control-sm" type="text" v-model="value.quantity" @change="
                    value.tbt = Number(
                      value.buyp * value.quantity -
                      (value.buyp * value.quantity * value.discount) / 100
                    ).toFixed(2) - value.amountdiscount;
                  value.taxamount = Number(
                    (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.total = Number(
                    Number(value.tbt) + (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  this.calc();
                  " />
                </td>
                <td>{{ value.unittype }}</td>
                <td style="display: flex">
                  <input type="number" v-model="value.amountdiscount" style="width: 70%" @change="
                    value.tbt = Number(
                      value.buyp * value.quantity -
                      (value.buyp * value.quantity * value.discount) / 100
                    ).toFixed(2) - value.amountdiscount;
                  value.taxamount = Number(
                    (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.total = Number(
                    Number(value.tbt) + (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  this.calc();
                  " />

                </td>
                <td>
                  <input class="form-control form-control-sm" type="text" v-model="value.buyp" @change="
                    value.tbt = Number(
                      value.buyp * value.quantity -
                      (value.buyp * value.quantity * value.discount) / 100
                    ).toFixed(2) - value.amountdiscount;
                  value.taxamount = Number(
                    (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  value.total = Number(
                    Number(value.tbt) + (value.tbt * value.tax) / 100
                  ).toFixed(2);
                  this.calc();
                  " />
                </td>

                <td>{{ value.tbt }}</td>
                <td style="display: flex">
                  <input type="number" v-model="value.tax" style="width: 100px" @change="
                    value.tbt =
                    value.buyp * value.quantity -
                    (value.buyp * value.quantity * value.discount) / 100 - value.amountdiscount;
                  value.taxamount = (value.tbt * value.tax) / 100;
                  value.total = value.tbt + (value.tbt * value.tax) / 100;
                  this.calc();
                  " />
                  %
                </td>
                <td>{{ value.taxamount }}</td>
                <td>{{ value.total }}</td>
                <td>
                  <a href="#" @click.prevent="del(id)"><i class="bi bi-x-circle-fill"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div class="total">
          <h4>{{ $t("total") }}</h4>
          <div class="total1">
            المبلغ قبل الضريبة <input type="text" disabled v-model="tbt" />
          </div>
          <div class="total1">
            قيمة الضريبة <input type="text" disabled v-model="taxamount" />
          </div>
          <div class="total1">
            بعد الضريبة <input type="text" disabled v-model="total" />
          </div>
        </div>
        <hr />
        <!-- <div class="row mb-2">
          <label class="col-sm-1 col-form-label-sm">{{ $t("branch") }} </label>
          <div class="col-sm-2">
            <select class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="branchno">
              <option v-for="val in branches" :key="val" :value="val.bid">
                {{ val.namear }}
              </option>
            </select>
          </div>
        </div> -->
        <div class="row mb-2">
          <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("notes") }}
          </label>
          <div class="col-sm-5">
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="notes"></textarea>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import vSelect from "vue-select";
export default {
  title: "Create Bill",
  components: {
    vSelect,
  },
  props: ["css"],
  async mounted() {
    const result3 = await axios.get("/data/allsupplier");
    if (result3.status == 200) {
      this.customers = result3.data.data;
    }
    const result2 = await axios.get("/data/allproducts");
    if (result2.status == 200) {
      this.products = result2.data.data;
    }
    const result4 = await axios.get("/data/allprojects");
    const result5 = await axios.get("/data/alltasks");
    // const result1 = await axios.get("/data/allbranches");
    const result6 = await axios.get("/data/returnbillID");
    if (result4.status == 200) {
      this.allprojects = result4.data.data;
      this.alltasks = result5.data.data;
      // this.branches = result1.data.data;
      this.id = Number(result6.data.data[0].count) + Number(1);
    }
  },
  data() {
    return {
      disabled: false,
      v$: useValidate(),
      id: "",
      data: [],
      number: "",
      date: "",
      description: "",
      customers: [],
      products: [],
      product: "",
      selection: false,
      customerid: "",
      customername: "",
      customerdata: "",
      taxamount: 0,
      tbt: 0,
      total: 0,
      addto: "",
      allprojects: [],
      alltasks: [],
      branches: [],
      taskno: "",
      projectno: "",
      branchno: "",
      duedate: "",
      supplydate: "",
      sdata: "",
      project: "",
      thetask: "",
      cofirmtax: false,
      invnum: ""
    };
  },
  validations() {
    return {
      date: { required },
      description: { required },
      project: { required },
      sdata: { required },
      invnum: { required }
    };
  },
  methods: {
    back() {
      this.$root.$router.push({
        path: "/AdminDashboard/AllInvoices/CreateInvoice",
      });
    },
    calc() {
      this.tbt = this.data.reduce((acc, array) => acc + Number(array.tbt), 0);
      this.taxamount = this.data.reduce(
        (acc, array) => acc + Number(array.taxamount),
        0
      );
      this.total = this.data.reduce(
        (acc, array) => acc + Number(array.total),
        0
      );
    },
    async del(id) {
      this.data.splice(id, 1);
      this.calc();
    },
    task() {
      const project = this.alltasks.filter((x) => x.id == this.taskno)[0]
        .projectid;
      this.projectno = project;
    },
    Addproduct() {
      if (this.product && this.thetask && this.project) {

        const data2 = this.data.filter((x) => x.pronamear == this.product.namear);
        if (data2.length == 0) {
          if (this.cofirmtax) {
            this.data.push({
              proid: this.product.pid,
              pronamear: this.product.namear,
              pronameen: this.product.nameen,
              salep: this.product.salep,
              salec: this.product.salec,
              snamear: this.product.snamear,
              snameen: this.product.snameen,
              buyp: this.product.buyp,
              buyc: this.product.buyc,
              bnamear: this.product.bnamear,
              bnameen: this.product.bnameen,
              discount: this.product.discount,
              unittype: this.product.unittype,
              tax: this.product.tax,
              sn: this.product.sn,
              stock: this.product.stock,
              barcode: this.product.barcode,
              quantity: 1,
              tbt:
                this.product.buyp * 1 -
                (this.product.buyp * 1 * this.product.discount) / 100,
              taxamount: Number(
                ((this.product.buyp * 1 -
                  (this.product.buyp * 1 * this.product.discount) / 100) *
                  this.product.tax) /
                100
              ).toFixed(2),
              total: Number(
                this.product.buyp * 1 -
                (this.product.buyp * 1 * this.product.discount) / 100 +
                ((this.product.buyp * 1 -
                  (this.product.buyp * 1 * this.product.discount) / 100) *
                  this.product.tax) /
                100
              ).toFixed(2),
              classname: this.thetask.name,
              classid: this.thetask.tid,
              item: this.product.item,
              project: this.project.name,
              projectid: this.project.pid,
              amountdiscount: 0
            });
          } else {
            this.data.push({
              proid: this.product.pid,
              pronamear: this.product.namear,
              pronameen: this.product.nameen,
              salep: this.product.salep,
              salec: this.product.salec,
              snamear: this.product.snamear,
              snameen: this.product.snameen,
              buyp: this.product.buyp,
              buyc: this.product.buyc,
              bnamear: this.product.bnamear,
              bnameen: this.product.bnameen,
              discount: this.product.discount,
              unittype: this.product.unittype,
              tax: 0,
              sn: this.product.sn,
              stock: this.product.stock,
              barcode: this.product.barcode,
              quantity: 1,
              tbt:
                this.product.buyp * 1 -
                (this.product.buyp * 1 * 0) / 100,
              taxamount: 0,
              total: Number(
                this.product.buyp * 1 -
                (this.product.buyp * 1 * this.product.discount) / 100 +
                ((this.product.buyp * 1 -
                  (this.product.buyp * 1 * this.product.discount) / 100) *
                  0) /
                100
              ).toFixed(2),
              classname: this.thetask.name,
              classid: this.thetask.tid,
              item: this.product.item,
              project: this.project.name,
              projectid: this.project.pid,
              amountdiscount: 0
            });
          }
          this.calc();
        }
      }
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.disabled = true;
        const result = await axios.post("/data/CreateReturnBill", {
          username: store.state.randp.username,
          due: this.date,
          description: this.description,
          duedate: this.duedate,
          supplydate: this.supplydate,
          sid: this.sdata.sid,
          sname: this.sdata.name,
          ownername: this.customerdata.name,
          details: JSON.stringify(this.data),
          tbt: this.tbt,
          tax: this.taxamount,
          tat: this.total,
          branch: this.branchno,
          project: this.project.name,
          projectid: this.project.pid,
          projectowner: this.project.ownername,
          projectownerid: this.project.ownerid,
          task: this.thetask.name,
          taskid: this.thetask.tid,
          notes: this.notes,
          ptype: this.project.ptype,
          pcom: this.project.commission,
          invnum: this.invnum
        });
        if (result.status == 200) {
          this.redirectTo({ val: "AllReturnsBills" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 2%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.ccenter button {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 20%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.part1 {
  display: flex;
  width: 100%;
}

.part11 {
  width: 50%;
  padding: 2%;
}

.part12 {
  width: 50%;
  padding: 2%;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>